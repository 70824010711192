import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "insuranceRow", "specialBillingRow", "labBillToInput" ]

  get billToValue() {
    if(this.hasLabBillToInputTarget) return this.labBillToInputTarget.value
    return ""
  }

  connect(){
    this.setRowDisplay(this.billToValue)
  }

  onLabBillToChange(ev) {
    this.setRowDisplay(ev.currentTarget.value)
  }

  setRowDisplay(value) {
    if(value === "SB") {
      this.insuranceRowTarget.classList.add("hide");
      this.specialBillingRowTarget.classList.remove("hide");
    } else {
      this.insuranceRowTarget.classList.remove("hide");
      this.specialBillingRowTarget.classList.add("hide");
    }
  }
}
