import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'id' ]

  openNewAttendanceModal() {
    document.getElementById('ajax_working').style.display = "block";
    let flash = document.getElementById("flash-message");
    fetch(`patient_attendances/new`)
      .then(function(response){
        if (!response.ok) {
          throw new Error(response.statusText)
        } else {
          return response.text()
        }
      })
      .then(function(html){
        document.getElementById("custom-order-dialog").innerHTML = html;
        $("#custom-order-dialog").dialog({
          title: 'New Attendance',
          modal: true,
          resizable: true,
          width: '60%',
        });
        Kipu.restrictDateTimePickers()
        document.getElementById('ajax_working').style.display = "none"
      })
      .catch(function(error){
        flash.innerHTML =  `<div class="notification-error mbottom1em">
                            <h2>Errors Found<a class="right" data-toggle="close" data-target="1"></a></h2>
                            <p>${error}</p>
                            </div>`;
        setTimeout(() => {flash.innerHTML = ""}, 5000)
      })
      .finally(function() {
        document.getElementById('ajax_working').style.display = "none";
      })
  }

  openEditAttendanceModal() {
    document.getElementById('ajax_working').style.display = "block";
    let flash = document.getElementById("flash-message");
    flash.innerHTML = ""
    fetch(`patient_attendances/${this.data.get('id')}/edit`)
      .then(function(response){
        if (!response.ok) {
          throw new Error(response.statusText)
        } else {
          return response.text()
        }
      })
      .then(function(html){
        document.getElementById("custom-order-dialog").innerHTML = html;
        $("#custom-order-dialog").dialog({
          title: 'Edit Attendance',
          modal: true,
          resizable: true,
          width: '60%',
        });
        Kipu.restrictDateTimePickers()
        document.getElementById('ajax_working').style.display = "none"
      })
      .catch(function(error){
        flash.innerHTML =  `<div class="notification-error mbottom1em">
                            <h2>Errors Found<a class="right" data-toggle="close" data-target="1"></a></h2>
                            <p>${error}</p>
                            </div>`;
        setTimeout(() => {flash.innerHTML = ""}, 5000)
      })
      .finally(function() {
        document.getElementById('ajax_working').style.display = "none";
      })
  }

}
