import { Controller } from "stimulus"

export default class extends Controller {

	updateStartTimeUpdate() {
		document.getElementById('eval_start_time_update').value = "true";
	}

	updateEndTimeUpdate() {
		document.getElementById('eval_end_time_update').value = "true";
	}
}