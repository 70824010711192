import { Controller } from "stimulus"

export default class extends Controller {
  
  static targets = [ "query",  "createRosterButton", "hideTableButton", 'settings']
  static values = { action: String }
  // Storing settings in local settings
  get settings() {
    return JSON.parse(this.data.get("settings"))
  }

  set settings(value) {
    this.data.set("settings", JSON.stringify(value))
  }   

  initialize(){
    $('.past_datepicker').datepicker({
      maxDate: 0,
      dateFormat: window.datepickerDateFormat,
    });

    // Submit on datepick
    $('#date').bind('change', function () {
      $(this).closest('form').submit();
    });

    $('#attendance-datepicker').bind('click', function () {
      if($('.past_datepicker').datepicker('widget').is(":visible")){
        $('.past_datepicker').removeClass('hidden').datepicker('hide').addClass('hidden');
      }else {
        $('.past_datepicker').removeClass('hidden').datepicker('show').addClass('hidden');
      }
    });
  }
  connect(){
    if (localStorage.getItem('attendance-settings')) {
      this.retrieveSettings()
    } else {
      let defaultSettings = {
        show_summary: true,
        expanded_table: true
      }
      // Set with default values if the key doesn't exist in localstorage.
      localStorage.setItem('attendance-settings', JSON.stringify(defaultSettings))
      this.settings = defaultSettings
    }
  }

  retrieveSettings(){
    // Grab settings persisted in local storage and write to the to data controller
    this.settings = JSON.parse(localStorage.getItem('attendance-settings'))
  }

  setSettings(setting, value){
    let s = this.settings
    s[setting] = value
    localStorage.setItem('attendance-settings', JSON.stringify(s))
    this.retrieveSettings();
  }
  
  createDailyRoster(){
    let that = this;
    let q = new URL(window.location.href).search;
    let options = {
      method: 'post',
      credentials: 'include',
      mode: 'same-origin',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Content-Type': 'application/json',
      }
    };
    document.getElementById("ajax_working").style.display = "block";
    fetch(`/attendances/create_roster${q}`, options)
    .then(function(response){
      if (!response.ok) {
        throw new Error(response.statusText)
      } else {
        setTimeout( () => {
            let div = document.getElementById('attendances-div');
            div.dispatchEvent(new Event('loadAttendancesTable'))
          }
        , 5000);
      }
    })
    .catch(function(error) {
      document.getElementById("flash-message").innerHTML = error;
    })
  }

  clearRoster(){
    if (window.confirm("Are you sure you'd like to clear the roster? This will delete all the attendance records for this date.")) {
      let that = this;
      let q = new URL(window.location.href).search;
      let options = {
        method: 'delete',
        credentials: 'include',
        mode: 'same-origin',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Content-Type': 'application/json',
        }
      };
      document.getElementById("ajax_working").style.display = "block";
      fetch(`/attendances/clear_roster${q}`, options)
      .then(function(response){
        if (!response.ok) {
          throw new Error(response.statusText)
        } else {
          setTimeout( () => {
              let div = document.getElementById('attendances-div');
              div.dispatchEvent(new Event('loadAttendancesTable'))
            }
          , 1000);
        }
      })
      .catch(function(error) {
        document.getElementById("flash-message").innerHTML = error;
      })
    }
  }

  toggleTableVisibility(e){
    if (e && e.isTrusted && this.settings.show_summary == false) {
      this.setSettings('show_summary', true)
    }
    else if (e && e.isTrusted && this.settings.show_summary == true) {
      this.setSettings('show_summary', false)
    }
    let table = document.getElementById('attendance-loc-table')
    if (this.settings.show_summary == true) {
      table.style.display = 'block'
      this.hideTableButtonTarget.innerText = 'Hide Summary'
    } else {
      table.style.display = 'none'
      this.hideTableButtonTarget.innerText = 'Show Summary'
    }
  }

  toggleCompactAttTable(e){
    if (e && e.isTrusted && this.settings.expanded_table == false) {
      this.setSettings('expanded_table', true)
    }
    else if (e && e.isTrusted && this.settings.expanded_table == true) {
      this.setSettings('expanded_table', false)
    }
    if (this.settings.expanded_table == false) {
      $('.attendance-image').hide();
      $('.attendance-timestamp').hide();
      $('#photo-column-th').hide();
      document.getElementById('show-table-button').innerHTML = 'Show Expanded Table';
    } else {
      $('.attendance-image').show();
      $('.attendance-timestamp').show();
      $('#photo-column-th').show();
      document.getElementById('show-table-button').innerHTML = 'Show Compact Table'
    }
  }

  toggleVisibility(e){
    let col = [].slice.call(document.getElementsByClassName('attendance-card')).filter(function(el) {
        return window.getComputedStyle(el).getPropertyValue('display') !== 'none'
      });
    if (col.length == 0 && e.target.dataset.showWhenEmpty == 'false') {
      e.currentTarget.style.display = 'none'
    } else if (col.length > 0 && e.target.dataset.showWhenEmpty == 'true') {
      e.currentTarget.style.display = 'none'
    }
    else {
      e.currentTarget.classList.remove('hide')
      e.currentTarget.style.display = 'inline-block'
    }
  }

  changeRosterButtonText(){
    let coll = document.getElementsByClassName('attendance-card')
    if (coll.length > 0) {
      this.createRosterButtonTarget.innerText = 'Update Roster'
    } else {
      this.createRosterButtonTarget.innerText = 'Create Roster'
    }
  }

  filterPatients() {
    $.fn.dataTable.tables({api: true}).search(this.queryTarget.value.toLowerCase()).draw()
    Array.from(document.getElementsByClassName('watch-attendance-table')).forEach((element) => {
              element.dispatchEvent(new Event('attendanceTableUpdated'))
            })
  }

  openBulkUpdateModal() {
    let state = event.target.dataset["presenceValue"];
    var options = {
      credentials: 'include',
      mode: 'same-origin',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      }
    };
    document.getElementById("ajax_working").style.display = "block";
    fetch(`/attendances/bulk_update_modal?presence=${state}`)
        .then(function(response){
          if (!response.ok) {
            throw new Error(response.statusText)
          } else {
            return response.text()
          }
        })
        .then(function(html){
          document.getElementById("custom-order-dialog").innerHTML = html;
          $("#custom-order-dialog").dialog({
            title: 'Edit Attendance',
            modal: true,
            resizable: true,
            width: "40%",
          });
          document.getElementById("presence").value = state
          if (state == "absent") {
            document.getElementById("absence_reason").disabled = false
          }
          document.getElementById("ajax_working").style.display = "none"
        })
        .catch(function(error){
          document.getElementById("flash-message").innerHTML = error;
        })
        .finally(function() {
          document.getElementById("ajax_working").style.display = "none";
        })
  }

  markAll(){
    let state = event.target.dataset["presenceValue"];
    let url = `/attendances/mark_all_${state}`
    if (window.confirm(`Are you sure you'd like to mark all ${state}, this will modify all the attendances showing?`)) {
      // Only select the items that are visible on the page because of filtering
      var col = [].slice.call(document.getElementsByClassName('attendance-card')).filter(function(el) {
        return window.getComputedStyle(el).getPropertyValue('display') !== 'none'
      });
      var params = {ids: col.map(function(el){return el.dataset.attendanceCardId}) };
      var flash = document.getElementById("flash-message");
      var that = this;
      flash.innerHTML = '';
      var options = {
                      method: 'PATCH',
                      credentials: 'include',
                      mode: 'same-origin',
                      headers: {
                        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
                        'Content-Type': 'application/json'
                      },
                      body: JSON.stringify(params)
                    };
      document.getElementById("ajax_working").style.display = "block";
      fetch(url, options)
      .then(function(response){
        if (!response.ok) {
          throw new Error(response.statusText)
        } else {
          return response.json();
        }
      })
      .then(function(json){
        col.forEach(el => {
          var con = that.application.getControllerForElementAndIdentifier(el,'attendance-card')
          if (json.ids.includes(con.data.get('id'))){
            let dtRowIndex = $(`[data-attendance-card-id= ${con.data.get('id')} ]`)[0]._DT_RowIndex;
            con.updatePresence(state, dtRowIndex);
            con.updateLoggedBy(json.updated_at, json.updated_by, timeZoneLong, dtRowIndex)
          }
        });
      })
      .catch(function(error){
        flash.innerHTML = '<div class="notification-error mbottom1em"><h2>Errors Found</h2><p>'+ error + '</p></div>';
      })
      .finally(function() {
        document.getElementById("ajax_working").style.display = "none"
        Array.from(document.getElementsByClassName('watch-attendance-table')).forEach((element) => {
              element.dispatchEvent(new Event('attendanceTableUpdated'))
            })
      })
    }
  }

  addPatientModal() {
    var attendance_date = document.getElementById('attendance-datepicker').getAttribute('data-attendance-date');
    var options = {
      credentials: 'include',
      mode: 'same-origin',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
    };
    document.getElementById("ajax_working").style.display = "block";
    fetch(`/attendances/patient_attendance_modal?attendance_date=${attendance_date}`, options)
        .then(function(response){
          if (!response.ok) {
            throw new Error(response.statusText)
          } else {
            return response.text()
          }
        })
        .then(function(html){
          document.getElementById("custom-order-dialog").innerHTML = html;
          $("#custom-order-dialog").dialog({
            title: 'Add Patient',
            modal: true,
            resizable: true,
            width: 600,
            height: 400,
            open: function(){ $('#attendance-patient-ids').select2({
              placeholder: "Add missing patients.",
              dropdownParent: $('#custom-order-dialog'),
              closeOnSelect: false,
              allowClear: true
            }).focus();
            $('.select2-search__field').click();
          }
          });
          document.getElementById("ajax_working").style.display = "none"
        })
        .catch(function(error){
          document.getElementById("flash-message").innerHTML = error;
        })
        .finally(function() {
          document.getElementById("ajax_working").style.display = "none";
        })
  }

  addPatienttoAttendance(){
    let p = document.getElementById('attendance-patient-ids');
    let patient_ids = Array.from(p.selectedOptions).map(opt=> opt.value);
    let date_string = document.getElementById('attendance-datepicker').getAttribute('data-attendance-date');
    let flash = document.getElementById("flash-message");
    let that = this;
    let options = {
      method: 'POST',
      credentials: 'include',
      mode: 'same-origin',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({patient_ids: patient_ids, attendance_date: date_string})
    };
    document.getElementById("ajax_working").style.display = "block";
    fetch("/attendances/", options)
        .then(function(response){
          if (!response.ok) {
            throw new Error(response.statusText)
          } else {
            let div = document.getElementById('attendances-div');
            div.dispatchEvent(new Event('loadAttendancesTable'))
          }
        })
        .catch(function(error){
          flash.innerHTML = '<div class="notification-error mbottom1em"><h2>Errors Found</h2><p>'+ error + '</p></div>';
        })
        .finally(function() {
          document.getElementById("ajax_working").style.display = "none";
          $("#custom-order-dialog").dialog('destroy')
          Array.from(document.getElementsByClassName('watch-attendance-table')).forEach((element) => {
            element.dispatchEvent(new Event('attendanceTableUpdated'))
          })
        })
  }
}