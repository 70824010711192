import {Controller} from "stimulus";

export default class extends Controller {
	updateLOCType(event) {
		const careLevelId = event.currentTarget.dataset.careLevelId;
		const careLevelType = event.currentTarget.options[event.currentTarget.selectedIndex].dataset.careLevelType;
		const locSelect = document.getElementById(`care_level_${careLevelId}_consider_as`);

		if (careLevelType !== 'Unspecified') {
			locSelect.value = careLevelType.toLowerCase();
			locSelect.dispatchEvent(new Event('change'));
		}
	}
}
