import { Controller } from 'stimulus'
import { htmlToElement } from "lib/htmlToElement"

/*
<%=
  stimulus_notification(
    type: <optional>,  # default :error
    title: <optional>, # Calculated if Not Given
    message: nil,      # Either Pass String or Block Content
    use_notification_warning: false,
    **wrapper_props,   # additional html props for wrapper element
    &block             # Either Pass Block or use `message` key
  )
%>
*/

const NoticeHTML = `
<section
  class="tw-rounded"
  data-controller="notification-boxes"
  aria-labelledby="notification-label-[ID]"
>
  <header class=""tw-grid tw-grid-flow-col tw-auto-cols-max tw-content-between tw-justify-between tw-w-full tw-m-0 tw-p-2">
    <h2 id="notification-label-[ID]" class="tw-p-0 tw-m-0"></h2>
    <button
      type="button"
      data-action="notification-boxes#hide"
      class="tw-m-0 tw-p-0 tw-bg-transparent tw-border-none"
      aria-label="close"
    >
      <i class="mdi mdi-close"></i>
    </button>
  </header>
  <div id="notification-body-[ID]" class="tw-py-4 tw-px-2" notification-boxes-target="body" role="log">
  </div>
</section>
`

var noticeId = 0

export default class NotificationBoxesController extends Controller {
  static targets = [ "body" ]

  static create(opts) {
    let { parent, type, title, message, messages, class: classNames, ...rest } = opts || {}
    type ||= "error"
    if(!title) {
      switch (type) {
        case "error":
          title = "Errors Found"
          break;
        case "warning":
          title = "Warning"
          break;
        default:
          title = "Notice"
          break;
      }
    }

    let cssClass
    switch (type) {
      case "error":
        cssClass = "notification-error"
        break;
      case "warning":
        cssClass = "notification-warning"
        break;
      default:
        cssClass = "notification-success"
        break;
    }

    const id = String(++noticeId)

    const notice = htmlToElement(NoticeHTML.replace(/\[ID\]/g, id))

    if(classNames) {
      classNames = Array.isArray(classNames) ? classNames : [classNames]
    } else {
      classNames = []
    }

    notice.classList.add(cssClass, ...classNames)
    notice.dataset.controller = "notification-boxes"
    for(const [key, value] of Object.entries(rest)) {
      if(key === "data" || key === "dataset") {
        for(const [dk, dv] of Object.entries(value)) {
          if(dk === "controller") {
            notice.dataset[dk] = `${notice.dataset.controller} ${dv}`
          } else {
            notice.dataset[dk] = dv
          }
        }
      } else {
        notice.setAttribute(key, value)
      }
    }

    const body = notice.getElementById(`notification-body-${id}`)

    this.addMessages(body, message || messages)

    if(parent instanceof HTMLElement) parent.appendChild(notice)

    return notice
  }

  static success(messages, parent) {
    return this.create({ type: "success", parent, messages })
  }

  static error(messages, parent) {
    return this.create({ type: "error", parent, messages })
  }

  static warning(messages, parent) {
    return this.create({ type: "warning", parent, messages })
  }

  static addMessages(parent, messages) {
    if(messages instanceof HTMLElement) {
      parent.appendChild(messages)
    } else {
      if(typeof messages === "string") {
        messages = messages.split(`\n`)
      }
      const wrapperType = parent.tagName.toUpperCase() === "UL" ? "li" : "p"
      messages.forEach(message => {
        const el = document.createElement(wrapperType)
        el.innerText = message
        parent.appendChild(el)
      })
    }
  }

  get parent() {
    return this.hasBodyTarget ? this.bodyTarget : this.element
  }

  initialize() {
    this.noticeId = ++noticeId
    this.element.controller = this
  }

  show() {
    this.element.classList.remove("tw-hidden")
  }

  hide() {
    this.element.classList.add("tw-hidden")
  }

  // WARNING: Removes element, `hide` is reusable
  dismiss() {
    this.element.parentElement.removeChild(this.element)
  }

  success(messages, show = true) {
    const parent = this.parent
    this.removeAllChildNodes(parent)
    this.element.classList.add("notification-success")
    this.element.classList.remove("notification-error")
    this.element.classList.remove("notification-warning")
    this.addMessages(parent, messages)
    if(show) this.show()
  }

  error(messages, show = true) {
    const parent = this.parent
    this.removeAllChildNodes(parent)
    this.element.classList.add("notification-error")
    this.element.classList.remove("notification-success")
    this.element.classList.remove("notification-warning")
    this.addMessages(parent, messages)
    if(show) this.show()
  }

  warning(messages, show = true) {
    const parent = this.parent
    this.removeAllChildNodes(parent)
    this.element.classList.add("notification-warning")
    this.element.classList.remove("notification-success")
    this.element.classList.remove("notification-error")
    this.addMessages(parent, messages)
    if(show) this.show()
  }

  addMessages(parent, messages) {
    this.constructor.addMessages(parent, messages)
  }

  removeAllChildNodes() {
    const parent = this.hasBodyTarget ? this.bodyTarget : this.element
    let header
    while (parent.firstChild) {
      if(parent.firstChild.tagName.toUpperCase() == "HEADER") {
        header = parent.firstChild
      }
      parent.removeChild(parent.firstChild)
    }
    if(header) parent.appendChild(this.header)
  }
}
