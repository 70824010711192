export default class KipuHighlighter {
  constructor(rangy) {
    this.rangy = rangy;
    this.highlights = new Map();
    this.highlighters = new Map();
    this.currentHighlighter = null;
    this.lastSerializedSelection = null;
  }

  createHighlighter(highlighter_class='highlightme') {
    const classApplier = this.rangy.createClassApplier(highlighter_class, { elementTagName: 'mark' });
    const highlighter = this.rangy.createHighlighter(null, 'TextRange');
    highlighter.addClassApplier(classApplier);

    return highlighter;
  }

  deserialize(serializedSelection) {
    return this.currentHighlighter.deserialize(serializedSelection);
  }

  getSelection() {
    return this.rangy.getSelection();
  }

  removeLastHighlight() {
    if (this.lastSerializedSelection) this.removeHighlights(this.lastSerializedSelection);
  }

  serialize(elementId, containerElementId) {
    const highlighter = this.createHighlighter();

    highlighter.highlightSelection('highlightme', {
      selection: this.getSelection(),
      containerElementId: containerElementId
    });
    const serializedSelection = highlighter.serialize();
    this.addHighlighter(serializedSelection, highlighter);

    let commentableId = elementId.split('_').pop();
    this.highlights.set(serializedSelection, commentableId);

    this.lastSerializedSelection = serializedSelection;

    return serializedSelection;
  }

  addHighlighter(id, highlighter) {
    this.highlighters.set(id, highlighter);
    this.currentHighlighter = highlighter;
  }

  getHighlighter(id) {
    this.currentHighlighter = this.highlighters.get(id);
    return this.currentHighlighter;
  }

  hasHighlighter(id) {
    return this.highlighters.has(id);
  }

  applyHighlight(commentableId, serializedSelection, containerElementId) {
    this.getOrCreateHighlighter(serializedSelection);
    const deserializedSelection = this.currentHighlighter.deserialize(serializedSelection);
    this.currentHighlighter.highlightSelection('highlightme', {
      selection: deserializedSelection,
      containerElementId: containerElementId
    });

    this.storeHighlight(commentableId, serializedSelection);
  }

  removeHighlighter(id) {
    this.highlighters.delete(id);
    this.removeHighlight(id);
  }

  removeHighlights(id) {
    const highlighter = this.highlighters.get(id);
    if (highlighter) {
      highlighter.removeAllHighlights();
    }
    this.removeHighlighter(id);
  }

  getOrCreateHighlighter(id) {
    if (this.hasHighlighter(id)) {
      this.currentHighlighter = this.getHighlighter(id);
      return this.currentHighlighter;
    }

    this.addHighlighter(id, this.createHighlighter());
  }

  removeAllHighlights() {
    for (let serializedSelection of this.highlighters.keys()) {
      this.highlighters.get(serializedSelection).removeAllHighlights();
    }
  }

  storeHighlight(commentableId, serializedSelection) {
    this.highlights.set(serializedSelection, commentableId);
  }

  removeHighlight(serializedSelection) {
    this.highlights.delete(serializedSelection);
  }

  restoreAndApplyAllHighlights() {
    (window.getSelection ? window.getSelection() : document.selection).empty();

    for (let serializedSelection of this.highlights.keys()) {
      const commentableId = this.highlights.get(serializedSelection);
      this.applyHighlight(commentableId, serializedSelection);
    }
  }
}
