import { Controller } from "stimulus"
import { htmlToElement } from "lib/htmlToElement"

const SquareBlock = `
<div
  class="tw-h-full tw-w-full tw-absolute tw-top-0 tw-left-0" role="image"
  aria-label="Initializing..."
>
  <div class="square-block tw-max-w-16">
    <div class="square-block--inner">
      <div class="square-block--content">
        <img src="/loader_wheels/wheel_w_message.svg?color=%231565c0&message=Initializing..." alt="Initializing" class="tw-w-full tw-h-full" />
      </div>
    </div>
  </div>
</div>
`

const TinyMCEConfig = {
  height: 500,
  mode: "textareas",
  branding: false,
  plugins: [
    "advlist autolink autosave link image lists charmap print preview hr anchor pagebreak",
    "searchreplace wordcount visualblocks visualchars code insertdatetime media nonbreaking",
    "table contextmenu directionality emoticons template textcolor paste textcolor colorpicker textpattern"
  ],
  toolbar1: "bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | styleselect formatselect fontselect fontsizeselect",
  toolbar2: "cut copy paste | searchreplace | bullist numlist | outdent indent blockquote | undo redo | link unlink anchor image code | insertdatetime preview | forecolor backcolor",
  toolbar3: "table | hr removeformat | subscript superscript | charmap emoticons | print | ltr rtl | visualchars visualblocks nonbreaking  pagebreak",
  pagebreak_separator: "<!-- page break --><div class=\"page-break\"></div>",
  menubar: false,
  toolbar_items_size: "small"
}

const ERBConfig = {
  protect: [
    /* Protect erb code */
    /<\%\=.*?\%>/g
  ]
}

let tinymce
const getTinyMCE = () => {
  if(tinymce) return Promise.resolve(tinymce)
  if(window.tinymce) {
    return Promise.resolve(tinymce = window.tinymce)
  }
  return new Promise(r => setTimeout(() => getTinyMCE().then(r), 1000))
}

export default class TinymceController extends Controller {
  static targets = [ "input" ]

  initialize() {
    this.connectedEl = {}
    this.getEditorManager()
  }

  connect() {
    this.inputTargets.forEach((el) => this.inputTargetConnected(el))
  }

  disconnect() {
    const connectedWas = this.connectedEl
    this.connectedEl = {}
    for(const key in connectedWas) {
      connectedWas[key]?.then?.(ed => ed.remove())
    }
  }

  inputTargetConnected(el) {
    if(el.tinyMceLoading) return false
    if(el.dataset.tinymceId && this.connectedEl[el.dataset.tinymceId]) return false
    el.dataset.tinymceId = this.genId()
    this.connectedEl[el.dataset.tinymceId] = this.initializeElement(el)
  }

  inputTargetDisconnected(el) {
    if(el.tinyMceLoading) return false
    this.disconnectTinyInstance(el)
  }

  getEditorManager() {
    if(this.getTinyMCE) return this.getTinyMCE
    return this.getTinyMCE = (async () => {
      return this.tinymce = await getTinyMCE()
    })()
  }

  async initializeElement(el) {
    this.loaderFor(el)
    try {
      const mce = this.getEditorManager()
      const config = Object.assign({}, TinyMCEConfig)
      if(el.dataset.erb === "true" || el.classList.contains("mce_erb")) {
        Object.assign(config, ERBConfig)
      }

      if(el.dataset.tinymceConfig) Object.assign(config, JSON.parse(el.dataset.tinymceConfig))
      for(const key in el.dataset) {
        if(/^tinymceConfig([A-Z][a-z_]+)+/.test(key)) {
          const configKey = key
            .replace(/^tinymceConfig/, "")
            .replace(/([A-Z])/g, match => `_${match.toLowerCase()}`)
            .replace(/^_/, "")
          config[configKey] = el.dataset[key]
        }
      }

      if(!el.id) el.id = el.dataset.tinymceId

      const tiny = await mce

      const ed = tiny.createEditor(
        el.id,
        config,
      )

      const init = new Promise(r => ed.on('init', r))
      await ed.render()
      await init

      console.debug(ed)

      ed.editorContainer.classList.add("tw-mce-editor", "tw-mce-editor-true-blue-200")

      return ed
    } catch(err) {
      console.error(err)
    } finally {
      delete el.tinyMceLoading

      this.hideLoaderFor(el)
    }
  }

  async disconnectTinyInstance(el) {
    const ed = await this.connectedEl[el.dataset.tinymceId]
    if(ed) ed.remove()
  }

  hideLoaderFor(el) {
    el.parentElement.querySelector("[role=image]")?.classList?.add?.("tw-hidden")
  }

  loaderFor(el) {
    let wrapper = el.closest(".mce-loader-wrapper")
    if(!wrapper) {
      el.tinyMceLoading = true
      wrapper = document.createElement("div")
      wrapper.classList.add("tw-relative", "mce-loader-wrapper", "tw-min-h-16")
      el.parentElement.replaceChild(wrapper, el)
      wrapper.appendChild(htmlToElement(SquareBlock))
      wrapper.appendChild(el)
    }

    wrapper.querySelector("[role=image]")?.classList?.remove?.("tw-hidden")
  }

  genId() {
    return `mce-${+new Date()}-${Math.floor(Math.random() * 1000)}`
  }
}
