import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "primaryInsurance", "secondaryInsurance", "insuranceSelect" ]

  connect(){
    this.setUnassignedLabTestingPrimaryAndSecondaryInsurance()
  }

  setUnassignedLabTestingPrimaryAndSecondaryInsurance() {
    let _this = this;
    // Looping on all Insurances
    Array.from(this.insuranceSelectTargets).forEach(function(ele, index) {
      let eleValue = document.getElementById("patient_insurances_attributes_" + index + "_status").value;

      // If element is 'Inactive' then only set Unassigned option for either 'Lab Testing > Lab Primary Insurance' or 'Lab Testing > Lab Secondary Insurance'
      if (eleValue === 'Inactive'){
        if (_this.primaryInsuranceTarget.value === ele.dataset.id) {
          // Set 'Unassigned' to 'Lab Testing > Lab Primary Insurance'
          _this.primaryInsuranceTarget.value = ''
          // Disable 'Lab Testing > Lab Secondary Insurance' drop-down in case of 'Lab Testing > Lab Primary Insurance' becomes Unassigned
          _this.secondaryInsuranceTarget.disabled = true
        } else if (_this.secondaryInsuranceTarget.value === ele.dataset.id) {
          // Set 'Unassigned' to 'Lab Testing > Lab Secondary Insurance'
          _this.secondaryInsuranceTarget.value = ''
        }
      }
    });
  }
}
