import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["presence", "absenceReason", "comment"]

  connect() {
    this.toggleAbsenceReasonSelect()
  }
  toggleAbsenceReasonSelect() {
    if (this.presenceTarget.value !== 'absent') {
      this.absenceReasonTarget[this.absenceReasonTarget.selectedIndex].removeAttribute('selected')
      this.absenceReasonTarget[0].setAttribute('selected', 'selected')
      this.absenceReasonTarget.value = ''
      this.absenceReasonTarget.disabled = true;
    } else {
      this.absenceReasonTarget.disabled = false
    }
  }

  editPatient(){
    var id = this.data.get("id");
    var newPresence = this.presenceTarget.value;
    var newAbsenceReason = this.absenceReasonTarget.value;
    var newComment = this.commentTarget.value;
    var that = this
    var options = {
      method: 'PUT',
      credentials: 'include',
      mode: 'same-origin',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ patient_attendance: {presence: newPresence , absence_reason: newAbsenceReason, comment: newComment}})
      };
    document.getElementById('ajax_working').style.display = "block";
    fetch("/attendances/" + id, options)
        .then(function(response){
          if (!response.ok) {
            throw new Error(response.statusText)
          } else {
            return response.json();
          }
        })
        .then(function(json){
          let updatedRow = $('[data-attendance-card-id="' + json.id + '"]')[0]
          let rowCon = that.application.getControllerForElementAndIdentifier(updatedRow,'attendance-card');
          let dtRowIndex = updatedRow._DT_RowIndex;
          let hiddenAbsenceReason = -4;
          rowCon.updatePresence(json.presence, dtRowIndex)
          $.fn.dataTable.tables({api: true}).cell(dtRowIndex, hiddenAbsenceReason).data(json.absence_reason)
          $.fn.dataTable.tables({api: true}).cell(`#attendance-comment-${json.id}`).data(json.comment).draw()
          rowCon.updateLoggedBy(json.updated_at, json.updated_by, timeZoneLong, dtRowIndex)
        })
        .catch(function(error){
          // Add error handling
        })
        .finally(function() {
          $("#custom-order-dialog").dialog("destroy")
          document.getElementById('ajax_working').style.display = "none"
        })
  }

  bulkUpdate() {
    let bulk_presence = this.presenceTarget.value
    let bulk_absence_reason = this.absenceReasonTarget.value
    let bulk_comment = this.commentTarget.value
    let url = `/attendances/mark_selected`
    let col = [].slice.call(document.getElementsByClassName("attendance-card")).filter(function(el) {
      return window.getComputedStyle(el).getPropertyValue("display") !== 'none' && el.firstElementChild.firstElementChild.checked
    });
    if (window.confirm("Are you sure you want to edit attendance as indicated for all selected? This change will overwrite any current documentation")) {
      $("#custom-order-dialog").dialog('close')
      let params = {ids: col.map(function(el){return el.dataset.attendanceCardId}), presence: bulk_presence, absence_reason: bulk_absence_reason, comment: bulk_comment };
      let flash = document.getElementById("flash-message");
      let that = this;
      flash.innerHTML = "";
      let options = {
        method: "PATCH",
        credentials: "include",
        mode: "same-origin",
        headers: {
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(params)
      };
      document.getElementById("ajax_working").style.display = "block";
      fetch(url, options)
          .then(function(response){
            if (!response.ok) {
              throw new Error(response.statusText)
            } else {
              return response.json();
            }
          })
          .then(function(json){
            col.forEach(el => {
              var con = that.application.getControllerForElementAndIdentifier(el,"attendance-card")
              let sanitized_comment = json.comment
              if (json.ids.includes(con.data.get("id"))){
                let dtRowIndex = $(`[data-attendance-card-id= ${con.data.get("id")} ]`)[0]._DT_RowIndex;
                con.updatePresence(bulk_presence, dtRowIndex);
                con.updateAbsenceReason(bulk_absence_reason, dtRowIndex);
                con.updateComment(sanitized_comment, dtRowIndex);
                con.updateLoggedBy(json.updated_at, json.updated_by, timeZoneLong, dtRowIndex);
                el.getElementsByTagName("input")[0].checked = false
              }
            });
          })
          .catch(function(error){
            flash.innerHTML = '<div class="notification-error mbottom1em"><h2>Errors Found</h2><p>'+ error + '</p></div>';
          })
          .finally(function() {
            let dropdownOptions = document.getElementById("mark-actions").nextElementSibling.children
            document.getElementById("select_all").checked = false
            document.getElementById("mark-actions").innerHTML = 'Mark All <span class="caret"></span>'
            for (let item of dropdownOptions) {
              item.dataset.action = item.dataset.action.replace("click->attendance-nav#openBulkUpdateModal", "click->attendance-nav#markAll");
            }
            document.getElementById("ajax_working").style.display = "none"
            Array.from(document.getElementsByClassName("watch-attendance-table")).forEach((element) => {
              element.dispatchEvent(new Event("attendanceTableUpdated"))
            })
          })
    }
  }
}