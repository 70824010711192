import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "url" ]
  
  connect() {
    var q = new URL(window.location.href).search;
    var url = this.data.get('url') + q;
    this.data.set('url', url);
    this.load()
  }

  load() {
    var options = {
      credentials: 'include',
      mode: 'same-origin',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      }
    };
    document.getElementById('ajax_working').style.display = "block";
    fetch(this.data.get("url"), options)
      .then(response => response.text())
      .then(html => {
        this.element.innerHTML = html;
        document.getElementById('ajax_working').style.display = "none"
        Array.from(document.getElementsByClassName('watch-attendance-table')).forEach((element) => {
            element.dispatchEvent(new Event('attendanceTableUpdated'))
          })
      })
  }
}