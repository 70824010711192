
// webpacker has this but we are trying to minimize the bloat according to this post:
// https://karen-kua.medium.com/the-end-of-babel-polyfill-the-more-efficient-alternative-95f959fef9c2
// import 'core-js/stable'
// import 'regenerator-runtime/runtime'

// Import and initialize stimulus
import { Application as Stimulus } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const stimulusApp = Stimulus.start()
const context = require.context("./stimulus_controllers", true, /\.js$/)
stimulusApp.load(definitionsFromContext(context))
