import { Controller } from "stimulus"

/*
This controller will order any children elements inside a parent element
To Use:
Add data-controller="sort-children" to the outermost element in your html.
Also add this meta data necessary to get the data sorted:
  data-sort-children-sort-by-value='' 
  data-sort-children-sort-keys-value='["value1","value2"]'
  data-sort-children-settings-name-space-value="name space for the local storage settings to persist settings across requests"
Add data-sort-children-target="sort" to the children to be sorted
Finally declare which data attributes to sort on.

 */

export default class extends Controller {
  static targets = ["sort", "button"];
  static values = {
                    sortBy: String,
                    sortKeys: Array,
                    sortDirection: String,
                    settingsNameSpace: String,
                    localSettings: Object,
                  };


  connect() { 
    if (!(localStorage.getItem(`${this.settingsNameSpaceValue}`))) {
      let defaultSettings = {
        sortBy: `${this.sortKeysValue[0]}`,
        sortDirection: `asc`,
      }
      // Set with default values if the key doesn't exist in localstorage.
      localStorage.setItem(`${this.settingsNameSpaceValue}`, JSON.stringify(defaultSettings))
    }
    this.getLocalSettings();
    this.sortByValue = this.localSettings.sortBy
    this.sortDirectionValue = this.localSettings.sortDirection
    this.sort();
  }


  getLocalSettings() {
    this.localSettings = JSON.parse(localStorage.getItem(`${this.settingsNameSpaceValue}`));
  }

  setLocalSettings() {
    localStorage.setItem(`${this.settingsNameSpaceValue}`, JSON.stringify(this.localSettings))
  }

  sort() {
    let sortByValue = this.localSettings.sortBy;
    switch(this.localSettings.sortDirection) {
      case 'desc':
        [...this.sortTargets].sort((a, b)=> JSON.parse(a.dataset.sortData)[sortByValue] < JSON.parse(b.dataset.sortData)[sortByValue] ? 1 : -1 )
          .forEach(node => this.element.appendChild(node));
        break;
      case 'asc':
        [...this.sortTargets].sort((a, b)=> JSON.parse(a.dataset.sortData)[sortByValue] > JSON.parse(b.dataset.sortData)[sortByValue] ? 1 : -1 )
          .forEach(node => this.element.appendChild(node));
        break;
      default:
        [...this.sortTargets].sort((a, b)=> JSON.parse(a.dataset.sortData)[sortByValue] > JSON.parse(b.dataset.sortData)[sortByValue] ? 1 : -1 )
          .forEach(node => this.element.appendChild(node));
    } 
  }


  resort(event) {
    event.preventDefault();
    let el = event.currentTarget;
    Array.from(this.buttonTargets).forEach(target => target.classList.remove('mdi-arrow-up-circle', 'mdi-arrow-down-circle' ))
    if (this.localSettings.sortBy == el.dataset.sortBy && this.localSettings.sortDirection == 'asc') {
      this.localSettings.sortDirection = 'desc'
      el.classList.add('mdi-arrow-down-circle')
    } else { 
      this.localSettings.sortBy = el.dataset.sortBy;
      this.localSettings.sortDirection = 'asc'
      el.classList.add('mdi-arrow-up-circle')
    }
    this.setLocalSettings();
    this.sort();
  }
  


}