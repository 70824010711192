export function getMetaElement(name) {
  return document.querySelector(`meta[name="${name}"]`)
}

export function getMetaContent(name) {
  const element = getMetaElement(name)
  return element && element.content
}

export const getMeta = getMetaContent
