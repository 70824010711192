import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "selectAllPatients", "selectedPatient"]
  connect(){
    let columnCount = document.getElementById('attendance-table').rows[0].cells.length - 1;
    let hiddenPresence = columnCount-4;
    let hiddenAbsenceReason = columnCount-3;
    let hiddenUpdatedBy = columnCount-2;
    let hiddenUpdatedAt = columnCount-1;
    $.fn.dataTable.moment( 'MM/DD/YYYY' );
    $.fn.dataTable.moment( 'MM/DD/YYYY h:mm a' );

    $.fn.dataTable.moment = function ( format, locale ) {
      let types = $.fn.dataTable.ext.type;

      // Add type detection
      types.detect.unshift( function ( d ) {
        return moment( d, format, locale, true ).isValid() ?
            'moment-'+format :
            null;
      } );

      // Add sorting method - use an integer for the sorting
      types.order[ 'moment-'+format+'-pre' ] = function ( d ) {
        return moment( d, format, locale, true ).unix();
      };
    };
    //initialize the datatable in the dom
    $(this.element).DataTable({
      initComplete: function() {
        var $buttons = $('.dt-buttons').hide();
        $('#exportCsv').off();
        $('#exportCsv').on('click', function() {
          $buttons.find('.buttons-csv').click();
        })

      },
      bautoWidth: false,
      retrieve: true,
      paging: false,
      searching: true,
      order: [[ 2, "asc" ]],
      "columnDefs": [ {
          "searchable": true,
          "orderable": false,
          "targets": [0, -1]
      },
        {
          "targets": [hiddenPresence, hiddenAbsenceReason, hiddenUpdatedBy, hiddenUpdatedAt],
          "visible": false
        }],
      buttons: [
      { extend: 'csvHtml5',
        exportOptions: {
          columns: ':not(:first-child, :nth-child(2), :last-child)'
        },
        filename: 'Attendance Report'
      }
    ],
      dom: '<"clear"><"right"B><"clear">rt<"clear"><"left"i><"clear">'
    });

    this.selectedPatientTargets.map(x => x.checked = false)
    this.selectAllPatientsTarget.checked = false
  }

  toggleSelectedPatient() {
    if (this.selectAllPatientsTarget.checked) {
      this.selectedPatientTargets.map(x => x.checked = true)
      this.toggleSelectionText(false)
    } else {
      this.selectedPatientTargets.map(x => x.checked = false)
      this.toggleSelectionText(true)
    }
  }

  toggleSelectAll() {
    if (this.selectedPatientTargets.map(x => x.checked).includes(false)) {
      this.selectAllPatientsTarget.checked = false
      this.toggleSelectionText(document.querySelectorAll('input[name="patient_ids[]"]:checked').length === 0)
    } else {
      this.selectAllPatientsTarget.checked = true
      this.toggleSelectionText(document.querySelectorAll('input[name="patient_ids[]"]:checked').length === 0)
    }
  }

  toggleSelectionText(showMarkAll) {
    let markAllAction = "click->attendance-nav#markAll"
    let markSelectedAction = "click->attendance-nav#openBulkUpdateModal"
    let dropdownOptions = document.getElementById("mark-actions").nextElementSibling.children
    if (showMarkAll) {
      document.getElementById("mark-actions").innerHTML = 'Mark All <span class="caret"></span>'
      this.updateDropdownAction(dropdownOptions, markSelectedAction, markAllAction)
    } else {
      document.getElementById("mark-actions").innerHTML = 'Mark Selected <span class="caret"></span>'
      this.updateDropdownAction(dropdownOptions, markAllAction, markSelectedAction)
    }
  }

  updateDropdownAction(options, oldValue, newValue) {
    for (let item of options) {
      item.dataset.action = item.dataset.action.replace(oldValue, newValue);
    }
  }
}
