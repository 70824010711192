export function htmlToElement(html) {
  const template = document.createElement("TEMPLATE")
  template.innerHTML = html.trim()
  const child = template.content.firstChild
  template.content.removeChild(child)
  return child
}

export function htmlToElements(html, js = false, allowRaw = false) {
  const template = document.createElement("TEMPLATE")
  template.innerHTML = html.trim()
  const nodes = [...template.content.childNodes]
  const jsNodes = []
  for(const script of template.content.querySelectorAll("script")) {
    if(js) {
      const el = document.createElement("SCRIPT")
      if(script.src) el.src = script.src
      else if(allowRaw) el.text = script.text
      el.type = "text/javascript"
      el.async = script.async
      if(el.src || el.text) jsNodes.push(el)
    }

    const index = nodes.indexOf(script)
    if(index != -1) nodes.splice(index, 1)
    else script.remove()
  }

  template.content.childNodes.forEach(node => template.content.removeChild(node))
  return [nodes, jsNodes]
}

export function safeLoadHtml(...args) {
  const [nodes, jsNodes] = htmlToElements(...args)
  jsNodes.forEach(node => document.body.appendChild(node))
  jsNodes.forEach(node => node.remove())
  return nodes
}

export default htmlToElements

window.safeLoadHtml = safeLoadHtml
