import { VueLoaderController } from "lib/stimulus-templates/vue-loader-controller"

export default class extends VueLoaderController {
  componentImport() {
    return import("components/organization/Units").then(module => module.default)
  }

  storeImport() {
    // use an empty promise if component has no store
    return import("store/organization/units").then(module => module.store)
  }
}
