import {Controller} from "stimulus";

const debounce = require("lodash/debounce");

export default class extends Controller {
  static targets = [ "reviewerButton", "sendRevisionButton" ]

  connect() {
    this.#autoSelectIfSingleReviewer();
  }

  modifyReviewer(ev) {
    const currentTarget =  ev.currentTarget;
    if (currentTarget.dataset.selected === 'true') {

      currentTarget.dataset.selected = 'false'

      this.sendRevisionButtonTarget.setAttribute('disabled', 'disabled');

      currentTarget.classList.remove("tw-bg-blue-200");
      currentTarget.classList.replace("hover:tw-bg-k-sky-blue", "hover:tw-bg-k-gray-300");

    } else {

      this.reviewerButtonTargets.forEach((element, _) => {
        element.dataset.selected = 'false'
        element.classList.remove("tw-bg-blue-200");
        element.classList.replace("hover:tw-bg-k-sky-blue", "hover:tw-bg-k-gray-300");
      });

      currentTarget.dataset.selected = "true";
      currentTarget.classList.add("tw-bg-blue-200");
      currentTarget.classList.replace("hover:tw-bg-k-gray-300", "hover:tw-bg-k-sky-blue");

      this.sendRevisionButtonTarget.removeAttribute('disabled');
      const href = this.sendRevisionButtonTarget.parentElement.href;

      let url = new URL(href);
      url.searchParams.set('assigned_to_id', currentTarget.dataset.user_id);
      this.sendRevisionButtonTarget.parentElement.href = `${url.pathname}${url.search}}`;
    }
  }

  #autoSelectIfSingleReviewer() {
    if (this.reviewerButtonTargets.length === 1) {
      this.reviewerButtonTarget.dispatchEvent(new Event('click'));
      this.reviewerButtonTarget.classList.add('tw-pointer-events-none');
    }
  }
}
