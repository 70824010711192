import { Controller } from "stimulus"

const newEl = (tag, ...classes) => {
  const el = document.createElement(tag)
  if(classes.length) el.classList.add(...classes)
  return el
}

export default class extends Controller {
  static targets = [
    "form",
    "table",
    "payerDescriptionLabel",
    "billTypeLabel",
    "billCodeLabel",
    "payerCodeLabel",
    "enabledLabel",
    "tableBody",
    "noRecordsRow",
    "removeButton"
  ]

  static inputNames = [
    "payer_description",
    "bill_type",
    "bill_code",
    "payer_code"
  ]

  connect() {
    this._counter = 0
  }

  get inputNames() {
    return this.constructor.inputNames
  }

  get billToValue() {
    if(this.hasLabBillToInputTarget) return this.labBillToInputTarget.value
    return ""
  }

  get nextCount() {
    ++this._counter
    return this.counter
  }

  get counter() {
    if(typeof this._counter !== "undefined") return this._counter
    this._counter = 0
    return this._counter
  }

  getLabel(name) {
    const label = name.replace(/_([a-z])/gi, (_, group) => group.toUpperCase())
    return this[`${label}LabelTarget`]
  }

  addRow(ev) {
    try {
      const row = newEl("TR", "input_fields_wrap")
      const offset = this.nextCount
      row.setAttribute("data-target", "special-billings-row.newRow")

      this.inputNames.forEach(name => {
        const td = newEl("TD"),
              input = newEl("INPUT")

        if(name != "payer_code") {
          input.setAttribute("required", true)
        }
        input.setAttribute("name", `special_billings[new_${offset}][${name}]`)
        input.setAttribute("type", "text")
        input.setAttribute("aria-labelledby", this.getLabel(name).getAttribute("id"))
        td.appendChild(input)

        row.appendChild(td)
      })

      const deleteCell = newEl("TD", "sb_action"),
            deleteButton = newEl("BUTTON", "icon-button"),
            deleteIcon = newEl("IMG", "icon22")

      // deleteButton.setAttribute("type", "button")
      deleteButton.setAttribute("data-action", "special-billing-form#removeRow")

      deleteIcon.setAttribute("alt", "Remove Row")
      deleteIcon.setAttribute("src", "/assets/ui/icon_delete.png")
      deleteButton.appendChild(deleteIcon)

      deleteCell.appendChild(deleteButton)

      row.appendChild(deleteCell)

      this.tableBodyTarget.appendChild(row)

      this.removeNoRecordsRow()
    } catch(err) {
      console.error(err)
    }
  }

  removeRow(ev) {
    const target = ev.currentTarget
    if(window.confirm("Are you sure you want to cancel adding this record?")) {
      let parent = target.parentElement
      while(parent && parent.tagName !== "TR") parent = parent.parentElement
      if(parent && parent.tagName === "TR") this.tableBodyTarget.removeChild(parent)
    }
  }

  removeNoRecordsRow() {
    if(this.hasNoRecordsRowTarget) this.tableBodyTarget.removeChild(this.noRecordsRowTarget)
  }
}
