import { fetch as fetchPolyfill } from 'whatwg-fetch'
import { getMetaContent, refreshCSRFTokens } from './CSRFTokens'

class FetchError extends Error {
  constructor(message, { response, result }) {
    super(message)
    this.fetchResponse = response
    this.fetchResult = result
  }

  get fetchStack() {
    if(this.statusMessage) {
      return `URL: ${this.fetchUrl}\nStatus: ${this.statusMessage}\n${this.stack}`
    } else {
      return this.stack
    }
  }

  get statusMessage() {
    if(this._statusMessage) return this._statusMessage
    try {
      const statusText = this.fetchResponse?.statusText
      const statusCode = this.fetchResponse?.status
      if(!statusText || !statusCode) throw new Error(statusCode ? String(statusCode) : "Unknown Status")
      this._statusMessage = `${statusCode} - ${statusText}`
    } catch(err) {
      this._statusMessage = "Unknown Status"
    }
    return this._statusMessage
  }

  get fetchUrl() {
    return this.fetchResponse.url
  }

  get requestId() {
    return this.fetchResponse?.headers?.get?.("X-Request-ID")
  }

  get fetchResult() {
    return this._result
  }

  set fetchResult(value) {
    return this._result = value
  }

  get fetchResponse() {
    return this._response
  }

  set fetchResponse(value) {
    this._response = value.clone()
  }
}

export function customFetch(url, options) {
  const { headers: givenHeaders, navigate, html, json, ...rest } = (options || {})
  const headers = { "X-CSRF-Token": getMetaContent("csrf-token"), ...(givenHeaders || {}) }

  if(html) headers.Accept = "text/html"
  if(json) headers.Accept = "application/json"

  let controller

  if(!rest.signal) {
    controller = new AbortController()
    rest.signal = controller.signal
  }

  const request = fetchPolyfill(url, {
    headers,
    credentials: "include",
    ...rest
  })

  if(controller) request.controller = controller

  return request.then(async (response) => {
    try {
      response.requestId = response.headers.get("X-Request-ID")
    } catch(_) {}

    if(response.status === 333) {
      let result, message
      try {
        if(navigate) {
          result = await response.clone().json()
          const { redirect_to } = result
          if(!redirect_to) throw new Error(`No Redirect Given: ${JSON.stringify(result)}`)
          window.location.href = redirect_to
          await new Promise(_ => {}) // infinite promise while page goes away
        }
      } catch(err) {
        console.error(err)
        message = "Server Failed to Redirect"
        throw new FetchError(message, { response, result })
      }
    } else if(response.status > 399) {
      let result, message
      try {
        result = await response.clone().json()
        message =
          (result.error && (typeof result.error === "string"))
            ? result.error
            : "Failed to Fetch"
      } catch(_) {
        message = "Failed to Fetch"
      }

      throw new FetchError(message, { response, result })
    } else if(response.redirected && /html/.test(response.headers.get("Content-Type"))) {
      const url = new URL(response.url, window.location)
      if(url.origin !== window.location.origin) {
        throw new Error(`Invalid Redirect: ${url.toString()}`)
      }
      const result = response.clone().text()
      if(/\<html\>.+\<body\>/m.test(result)) {
        document.querySelector("html").innerHTML = result
        window.history.pushState({}, '', url)
        refreshCSRFTokens()
      }
    }

    return response
  })
}

export function fetchHeadless(url, options) {
  options = {...(options || {})}
  const { headless: _headless, ...rest } = options
  const headers = options?.headers || {}
  headers["X-Kipu-No-Layout"] = true
  return customFetch(url, { navigate: true, ...rest, headers })
}
