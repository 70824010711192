import { Controller } from "stimulus"

/*
This controller will remove images from the page that are corrupted or missing in order
to prevent long alt strings from displaying and throwing off the layout.
To Use:
Add data-controller="image-validator" to the outermost element in your html
Add data-image-validator-target="imageVerification" to the image tag
 */

export default class extends Controller {
  static targets = ["imageVerification"]

  connect() {
    this.imageVerificationTargets.forEach(function(image) {
        if(image.naturalWidth == 0) {
          image.remove()
        }
    })
  }
}