import { Controller } from "stimulus"
import { fetchHeadless } from "lib/customFetch"
import debounce from "lodash/debounce"

const camelize = s => s.replace(/-./g, x=>x[1].toUpperCase())

const SquareBlock = `
<div class="tw-flex tw-flex-row tw-items-center tw-justify-center tw-h-36 tw-w-36 tw-p-4 tw-bg-opacity-50 tw-bg-white tw-absolute tw-bottom-0 tw-right-0">
  <div class="square-block">
    <div class="square-block--inner">
      <div class="square-block--content">
        <img src="/loader_wheels/wheel_w_message.svg?message=Loading..." alt="Loading" class="tw-w-full tw-h-full" />
      </div>
    </div>
  </div>
</div>
`

export default class AssignableDocumentsController extends Controller {
  static targets = [
    "wrapper",
    "dialog",
    "table",
    "filter",
    "toggle",
    "loader",
    "error",
    "results",
    "favorites"
  ]

  static values = {
    path: String,
    patientId: Number,
  }

  get identifierClass() {
    return this._identifierClass ||= camelize(this.identifier)
  }

  get params() {
    return this._params || this.buildParams()
  }

  set params(value) {
    return this._params = value
  }

  initialize() {
    this.queueSearch = debounce(() => this.fetchTables(), 100)
    this.queueChange = debounce(() => this.changeParams(), 500)
  }

  connect() {
    this.loadingCount = 0
    if(!this.hasLoaderTarget) this.createLoader()
    this.buildParams()
    // if(this.hasTableTarget) this.tableTargets.forEach(el => this.loadTable(el))
    // this.documentTypes = []
  }

  createLoader(displayed = false) {
    const div = document.createElement("div")
    div.innerHTML = SquareBlock
    div.dataset[`${this.identifierClass}Target`] = "loader"
    if(!displayed) div.classList.add("tw-hidden")
    this.element.appendChild(div)
  }

  displayLoader() {
    this.loadingCount++
    if(!this.hasLoaderTarget) this.createLoader(true)
    else this.loaderTarget.classList.remove("tw-hidden")
  }

  hideLoader() {
    this.loadingCount--
    if(this.hasLoaderTarget && this.loadingCount < 1) this.loaderTarget.classList.add("tw-hidden")
  }

  changeParams() {
    const paramsWas = this.params.toString()
    if(this.buildParams().toString() !== paramsWas) this.queueSearch()
  }

  buildParams() {
    const params = new URLSearchParams()

    if(this.hasFilterTarget) {
      this.filterTargets.forEach(filter => {
        if(filter.value) {
          params.append(filter.name, filter.value)
        }
      })
    }

    this.toggleTargets.forEach(checkbox => {
      if(checkbox.checked) {
        params.append("document_types[]", checkbox.value)
      }
    })

    return this.params = params
  }

  buildUrl(basePath, subPath, includeParams) {
    const baseUrl = new URL(basePath, window.location)
    return new URL(
      `${baseUrl.origin}${baseUrl.pathname}${subPath || ""}?${
        new URLSearchParams([
          ...baseUrl.searchParams.entries(),
          ...(includeParams ? this.params.entries() : [])
        ])
      }`
    )
  }

  async searchTables() {
    try {
      this.displayLoader()
      const promises = this.tableTargets.map(table => this.search(table))
      await Promise.all(promises)
    } catch(err) {
      console.error(err)
    } finally {
      this.hideLoader()
    }
  }

  async fetchTables() {
    try {
      this.displayLoader()
      const url = this.buildUrl(this.pathValue, false, true)
      const response = await fetchHeadless(url, {json: true})
      const data = await response.json()
      const resultTypes = ["results", "favorites"]

      resultTypes.forEach(target => {
        const el = this[`${target}Target`]
        this.removeAllChildNodes(el)
        el.innerHTML = data[target]
      })
      if(!window.jQuery.fn.DataTable) {
        let count = 0
        await new Promise(resolve => {
          const loop = () => {
            if(window.jQuery.fn.DataTable || (count++ > 600)) return resolve()
            setTimeout(loop, 100)
          }
          loop()
        })
      }
    } catch(err) {
      console.error(err)
      this.onError(err)
    } finally {
      this.hideLoader()
    }
  }

  async search(table) {
    if(!table.controller?.dataTable && table.closest(".dataTables_scrollHead")) return false

    try {
      const url = this.buildUrl(table.dataset.searchPath, false, true)
      const response = await fetchHeadless(url, {html: true})

      let parent
      if(table.controller?.originalParent) {
        parent = table.controller.originalParent
        table.controller.teardown()
      } else {
        parent = table.closest("[data-assignable-documents-target=search]")
              || table.closest("[data-assignable-documents-target=favorites]")
      }

      this.removeAllChildNodes(parent)
      parent.innerHTML = await response.text()
    } catch(err) {
      console.error(err)
    }
  }

  favorite(ev) {
    const el = ev.currentTarget
    this.executeFavorite(el, "POST")
  }

  unfavorite(ev) {
    const el = ev.currentTarget
    this.executeFavorite(el, "DELETE")
  }

  async executeFavorite(el, method) {
    try {
      this.displayLoader()
      if(this.hasErrorTarget) this.errorTarget.controller.hide()
      const id = this.getDocumentId(el)
      const url = this.buildUrl(this.pathValue, `/favorites`, false)
      const body = new FormData()

      body.append("assignable_document_id", id)
      await fetchHeadless(
        url,
        {
          headless: true,
          json: true,
          method,
          body
        }
      )
      await this.fetchTables()
    } catch(error) {
      this.onError(error)
    } finally {
      this.hideLoader()
    }
  }

  async addToChart(ev) {
    const el = ev.currentTarget
    try {
      this.displayLoader()
      if(this.hasErrorTarget) this.errorTarget.controller.hide()

      const id = this.getDocumentId(el)
      const shouldEdit = el.dataset.edit == "true"
      const url = new URL(this.pathValue, window.location)
      const params = new FormData()
      params.append("assignable_document_id", id)
      const response = await fetchHeadless(
        url,
        {
          headless: true,
          html: true,
          method: "POST",
          body: params
        }
      )
      if(shouldEdit) {
        const targetPath = (await response.json())?.assigned_document_path
        if(targetPath) return window.location.href = targetPath
      }
      await this.fetchTables()
    } catch(err) {
      this.onError(err)
    } finally {
      this.hideLoader()
    }
  }

  onError(err) {
    if(this.hasErrorTarget) {
      this.errorTarget.controller.error(err.toString(), true)
    }
    console.error(err)
  }

  trDetailsFor(el) {
    const id = this.getDocumentId(el)
    const tr = el.closest("tr")
    const row = this.getTableTarget(tr).row(tr)
    const icon = tr.querySelector(".mdi-chevron-down,.mdi-chevron-up")

    return { id, tr, row, icon }
  }

  showDetails(ev) {
    const el = ev.currentTarget
    return this.displayDetails(this.trDetailsFor(el))
  }

  async displayDetails(data) {
    const { id, row, icon } = data

    if(row.child() && row.child().length) {
      row.child.show()
      if(icon) {
        icon.classList.add("mdi-chevron-up")
        icon.classList.remove("mdi-chevron-down")
      }
    } else {
      try {
        this.displayLoader()
        const url = this.buildUrl(this.pathValue, `/${id}`, false)
        const result = await fetchHeadless(url, {html: true})
        row.child(await result.text(), "tw-bg-k-gray-100 shadow-bottom tw-relative").show()
        if(icon) {
          icon.classList.add("mdi-chevron-up")
          icon.classList.remove("mdi-chevron-down")
        }
      } catch(err) {
        console.error(err)
      } finally {
        this.hideLoader()
      }
    }
  }

  toggleDetails(ev) {
    const el = ev.currentTarget
    const { id, tr, row, icon } = this.trDetailsFor(el)

    if(row.child.isShown()) {
      row.child.hide()
      if(icon) {
        icon.classList.add("mdi-chevron-down")
        icon.classList.remove("mdi-chevron-up")
      }
    } else {
      return this.displayDetails({ id, tr, row, icon })
    }
  }

  async reloadDetails(ev) {
    try {
      const el = ev.currentTarget
      const id = this.getDocumentId(el)
      const td = el.closest("td")
      this.displayLoader()
      const url = new URL(`${this.pathValue}/${id}`, window.location)
      const result = await fetchHeadless(url, {html: true})
      td.innerHTML = await result.text()
    } catch(err) {
      console.error(err)
    } finally {
      this.hideLoader()
    }
  }

  getTableTarget(tr) {
    const el = tr.closest("table")
    return el.controller.dataTable
  }

  getDocumentId(el) {
    if(!el) return null
    if(el.dataset.documentId) return el.dataset.documentId
    if(el.tagName.toLowerCase() == "table") return null
    return this.getDocumentId(el.parentElement)
  }

  removeAllChildNodes(parent) {
    while (parent.firstChild) parent.removeChild(parent.firstChild)
  }
}
