import { duration } from "moment"
import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    if (!(localStorage.getItem('attendance-settings'))) {
      let defaultSettings = {
        show_summary: true,
        expanded_table: true
      }
      // Set with default values if the key doesn't exist in localstorage.
      localStorage.setItem('attendance-settings', JSON.stringify(defaultSettings))
    }
  }

  generate() {
    duration;
    let settings = JSON.parse(localStorage.getItem('attendance-settings'))
    const t = this.element
    const url = new URL(window.location.href);
    t.style.display = 'block'
    if (settings.show_summary == false && url.pathname == '/attendances') {
      t.style.display = 'none'
    }
    let a = [].slice.call(document.getElementsByClassName('attendance-card')).filter(function(el) {
      return window.getComputedStyle(el).getPropertyValue('display') !== 'none'
    });
    if ( a.length == 0 ) {
      t.tBodies[0].innerHTML = "<tr><td colspan=5>Nothing to show.</td></tr>"
      return
    }
    t.tBodies[0].innerHTML = null
    const presences = ['present', 'absent', 'unrecorded']
    let tableLOCS = a.map(el => el.dataset.attendanceCardLoc).filter((value, index, self) => self.indexOf(value) === index).sort()
    let presenceTotals = {present: 0, absent: 0, unrecorded:0}
    tableLOCS.forEach((loc, index)=> {
      let loc_label = loc
      if (loc == '') {
        loc_label = 'None'
      }
      let row = t.tBodies[0].insertRow(index)
      let label = row.insertCell(0)
      label.innerText = loc_label // row label
      let total = 0
      presences.forEach( (val, index) => {
        let locCards = a.filter(el => el.dataset.attendanceCardLoc === loc)
        let count = locCards.filter(el => el.dataset.attendanceCardPresence === val ).length
        let cell = row.insertCell(index + 1)
        cell.innerText = count;
        total += count; // row total
        presenceTotals[val] += count // colum total
      });
      let cell = row.insertCell(-1)
      cell.innerText = total
    })
    let totalsRow = t.tBodies[0].insertRow(-1)
    let label = totalsRow.insertCell(0)
    label.innerText = "Totals"
    presences.forEach( (val, index) => {
      let count = presenceTotals[val]
      let cell = totalsRow.insertCell(index + 1)
      cell.innerText = count;
    });
    let cell = totalsRow.insertCell(-1)
    cell.innerText = Object.values(presenceTotals).reduce((n, el)=> n+=el)
  }
}