import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "id",
    "presentButton",
    "absentButton",
    "presence",
    "name",
    "loc",
    "updatedBy",
    "updatedAt",
    "loggedLabel",
    "comment"
  ]

  initialize() {
    this.updatePresence(this.presence)
    this.updateLoggedBy(this.updatedAt, this.updatedBy)
  }

  get presence() {
    return this.data.get("presence")
  }

  set presence(value) {
    this.data.set("presence", value)
  }

  get updatedBy() {
      return this.data.get("updatedBy")
  }

  set updatedBy(value){
    this.data.set('updatedBy', value)
  }

  get updatedAt() {
      return this.data.get("updatedAt")
  }

  set updatedAt(value){
    this.data.set('updatedAt', value)
  }

  updateLoggedBy(updatedAt = "", updatedBy = "", tz = null, rowIndex = null){
    this.updatedBy = updatedBy
    this.updatedAt = updatedAt
    if (this.updatedBy == ""){
      return
    }
    let date = new Date(this.updatedAt);
    let formatted_date = "";
    if (tz == null) {
      formatted_date = date.toLocaleString();
    } else {
      formatted_date = date.toLocaleString('en-US', {timeZone: tz});
    }
    this.loggedLabelTarget.innerHTML = `${this.updatedBy} at ${formatted_date}`
    if (rowIndex !== null){
      let columnCount = $.fn.dataTable.tables({api: true}).columns()[0].length - 1
      let hiddenUpdatedBy = columnCount-2;
      let hiddenUpdatedAt = columnCount-1;
      $.fn.dataTable.tables({api: true}).cell( { row: rowIndex, column: hiddenUpdatedBy } ).data(this.updatedBy)
      $.fn.dataTable.tables({api: true}).cell( { row: rowIndex, column: hiddenUpdatedAt } ).data(formatted_date)
    }
  }

  togglePresence(event) {
    let id = this.data.get('id');
    let dtRowIndex = $(`[data-attendance-card-id= ${id} ]`)[0]._DT_RowIndex;
    let newPresence;
    if (event.currentTarget.value === 'present') {
      newPresence = this.presence === 'present' ? 'unrecorded' : 'present';
    } else {
      newPresence = this.presence === 'absent' ? 'unrecorded' : 'absent';
    }
    let flash = document.getElementById("flash-message");
    let that = this;
    flash.innerHTML = '';
    let options = {
                    method: 'PUT',
                    credentials: 'include',
                    mode: 'same-origin',
                    headers: {
                      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
                      'Content-Type': 'application/json'
                    },
                    body: '{ "patient_attendance" : {"presence": "' + newPresence + '"} }'
                  };
    document.getElementById('ajax_working').style.display = "block";
    fetch("/attendances/" + id, options)
    .then(function(response){
      if (!response.ok) {
        throw new Error(response.statusText)
      } else {
        return response.json();
      }
    })
    .then(function(json){
      that.updatePresence(json.presence, dtRowIndex);
      that.updateLoggedBy(json.updated_at, json.updated_by, timeZoneLong, dtRowIndex)
    })
    .catch(function(error){
      flash.innerHTML = '<div class="notification-error mbottom1em"><h2>Errors Found</h2><p>'+ error + '</p></div>'
    })
    .finally(function() {
      document.getElementById('ajax_working').style.display = "none"
      document.getElementById('attendance-loc-table').dispatchEvent(new Event('attendanceTableUpdated'))
    })
  }

  openModal() {
    var id = this.data.get('id');
    var options = {
      credentials: 'include',
      mode: 'same-origin',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      }
    };
    document.getElementById('ajax_working').style.display = "block";
    fetch(`/attendances/${id}/modal`)
    .then(function(response){
      if (!response.ok) {
        throw new Error(response.statusText)
      } else {
        return response.text()
      }
    })
    .then(function(html){
      document.getElementById("custom-order-dialog").innerHTML = html;
      $("#custom-order-dialog").dialog({
        title: 'Edit Attendance',
        modal: true,
        resizable: true,
        width: '60%',
      });
      document.getElementById('ajax_working').style.display = "none"
    })
    .catch(function(error){
      document.getElementById("flash-message").innerHTML = error;
    })
    .finally(function() {
      document.getElementById('ajax_working').style.display = "none";
    })
  }

  deleteAttendance() {
    var id = this.data.get('id');
    var that = this;
    var options = {
      method: 'delete',
      credentials: 'include',
      mode: 'same-origin',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Content-Type': 'application/json'
      }
    };
    document.getElementById('ajax_working').style.display = "block";
    fetch(`/attendances/${id}`, options)
    .then(function(response){
      if (!response.ok) {
        throw new Error(response.statusText)
      } else {
        that.element.remove();
      }
    })
    .catch(function(error){
      document.getElementById("flash-message").innerHTML = error;
    })
    .finally(function() {
      document.getElementById('ajax_working').style.display = "none";
      Array.from(document.getElementsByClassName('watch-attendance-table')).forEach((element) => {
              element.dispatchEvent(new Event('attendanceTableUpdated'))
            })
    })
  }

  updatePresence(value, rowIndex = null) {
    this.presence = value
    switch(value) {
      case 'unrecorded':
        this.presentButtonTarget.classList.remove("present-status");
        this.absentButtonTarget.classList.remove("absent-status");
        break;
      case 'absent':
        this.presentButtonTarget.classList.remove("present-status");
        this.absentButtonTarget.classList.add("absent-status");
        break;
      case 'present':
        this.presentButtonTarget.classList.add("present-status");
        this.absentButtonTarget.classList.remove("absent-status");
    }
    if (rowIndex !== null){
      let columnCount = $.fn.dataTable.tables({api: true}).columns()[0].length - 1;
      $.fn.dataTable.tables({api: true}).cell( { row: rowIndex, column: columnCount-4 } ).data(value.substr(0,1).toUpperCase()+value.substr(1))
    }
  }

  updateComment(value, rowIndex = null) {
    this.comment = value
    if (rowIndex !== null){
      let columnCount = $.fn.dataTable.tables({api: true}).columns()[0].length - 1;
      $.fn.dataTable.tables({api: true}).cell( { row: rowIndex, column: columnCount-5 } ).data(this.comment)
    }
  }

  updateAbsenceReason(value, rowIndex = null) {
    this.absence_reason = value
    if (rowIndex !== null){
      let columnCount = $.fn.dataTable.tables({api: true}).columns()[0].length - 1;
      $.fn.dataTable.tables({api: true}).cell( { row: rowIndex, column: columnCount-3 } ).data(this.absence_reason)
    }
  }
}