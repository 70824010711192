import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["searchTerm", "statusFilter"];

  initialize() {
    this.#reportTable();
  }

  connect() {
    // 'selected' attribute on status filter option does display option text in select unless this is done
    this.statusFilterTarget.options[0].selected = true;
  }

  searchRevisionRequests(event) {
    this.#reportTable();
    document.getElementById("input_warning").innerHTML = "";
    document.getElementById("ajax_working").style.display = "block";
    this.reportDataTable
      .search(JSON.stringify({
        searchTerm: this.searchTermTarget.value,
        statusFilter: this.statusFilterTarget.value
      })).draw();
    document.getElementById("ajax_working").style.display = "none";
  }

  exportReport(event) {
    document.getElementById("ajax_working").style.display = "block";
    const form = event.currentTarget.parentElement;
    form.querySelector("[name='search']").value = JSON.stringify({ searchTerm: this.searchTermTarget.value, statusFilter: this.statusFilterTarget.value });
    form.querySelector("[name='sort_column']").value = this.reportDataTable.settings().init().columns[this.reportDataTable.order()[0][0]]["data"];
    form.querySelector("[name='sort_order']").value = this.reportDataTable.order()[0][1];
    document.getElementById("ajax_working").style.display = "none";
  }

  #reportTable() {
    if (!$.fn.DataTable.isDataTable("#revision_request_report_table")) {
      $.fn.dataTable.ext.classes.sPageButton = "pagination-item tw-relative tw-justify-center tw-inline-flex tw-items-center tw-bg-white tw-text-sm tw-font-normal tw-text-gray-700 tw-text-med hover:tw-bg-k-true-blue-50 tw-leading-9 tw-h-9 tw-w-9 tw-group tw-rounded-full";
      $.fn.dataTable.ext.classes.sPageButtonActive = "pagination-item tw-relative tw-justify-center tw-inline-flex tw-items-center tw-text-sm tw-font-bold tw-text-gray-700 tw-text-med tw-bg-k-true-blue-50 tw-leading-9 tw-h-9 tw-w-9 tw-group tw-rounded-full";
      $.fn.dataTable.ext.classes.sPageButtonDisabled = "pagination-item tw-relative tw-justify-center tw-inline-flex tw-items-center tw-bg-white tw-text-sm tw-font-normal tw-text-gray-700 tw-text-light tw-leading-9 tw-h-9 tw-w-9 tw-group tw-rounded-full";
      this.reportDataTable = $("#revision_request_report_table").DataTable({
        ajax: {
          "url": "/reviews/revision_requests/search"
        },
        bAutoWidth: false,
        retrieve: true,
        serverSide: true,
        pageLength: 25,
        paging: true,
        pagingType: "full_numbers",
        language: {
          "info": "_START_ - _END_ of _TOTAL_",
          infoEmpty: "0 - 0 of 0",
          paginate: {
            sFirst: '<i class="mdi mdi-page-first" ></i>',
            sNext: '<i class="mdi mdi-chevron-right" ></i>',
            sPrevious: '<i class="mdi mdi-chevron-left" ></i>',
            sLast: '<i class="mdi mdi-page-last" ></i>'
          }
        },
        searching: true,
        order: [[ 7, "desc" ]],
        columns: [
          {"data": "patient_name", "name": "patient_name", "title": "Patient Name", "defaultContent": "", className: "dt-left", "width": "10%"},
          {"data": "patient_evaluation_patient_mr", "name": "patient_evaluation_patient_mr", "title": "MR Number", "defaultContent": "", className: "dt-left", "width": "7%"},
          {"data": "patient_evaluation_patient_number", "name": "patient_evaluation_patient_number", "title": "Patient ID", "defaultContent": "", className: "dt-left", "width": "6%"},
          {"data": "patient_evaluation_patient_location_name", "name": "patient_evaluation_patient_location_name", "title": "Location", "defaultContent": "", className: "dt-left", "width": "9%"},
          {"data": "patient_evaluation_name", "name": "patient_evaluation_name", "title": "Evaluation Name", "defaultContent": "", className: "dt-left", "width": "9%"},
          {"data": "status", "name": "status", "title": "Status", "defaultContent": "", className: "dt-left", "width": "7%"},
          {"data": "initiated_by_full_name", "name": "initiated_by","title": "Requested By", "defaultContent": "", className: "dt-left", "width": "10%"},
          {"data": "created_at", "name": "created_at", "title": "Requested At", "defaultContent": "", className: "dt-left", "width": "11%"},
          {"data": "assigned_to_full_name", "name": "refinement_sent_to","title": "Refinement Sent To", "defaultContent": "", className: "dt-left", "width": "10%"},
          {"data": "completed_at", "name": "completed_at", "title": "Refined At", "defaultContent": "", className: "dt-left", "width": "11%"},
          {"data": "exception", "name": "exception","title": "Exception", "defaultContent": "", className: "dt-left", "width": "10%"}
        ],
        dom: '<"clear">rt<"clear"><"right" ip><"clear">'
      });
      this.searchTermTarget.value = "";
      this.statusFilterTarget.value = "";
    }
  }
}
