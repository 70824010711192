import { Controller } from "stimulus"

export class VueLoaderController extends Controller {
  static targets = [
    "loader",
    "component"
  ]

  connect() {
    if(this.hasComponentTarget) this.componentTargets.forEach(el => this.loadComponents(el))
  }

  componentTargetConnected(el) {
    this.loadComponents(el)
  }

  componentTargetDisconnected(el) {
    if(el.vueComponent) {
      el.vueComponent.$destroy()
      delete el.vueComponent
    }
  }

  componentImport() {
    throw new Error("Component Path Must be Statically Defined in Subclass")
  }

  storeImport() {
    /*
    example:
    return import("store/organization/units")
      .then(module => module.store)
    */
    return Promise.resolve()
  }

  vuetifyImport() {
    return import("plugins/vuetify").then(module => module.default)
  }

  vueImport() {
    return import('vue').then(module => module.default)
  }

  async loadComponents(el) {
    try {
      const Vue = await this.vueImport()
      const ComponentImport = this.componentImport()
      const StoreImport = this.storeImport()
      const VuetifyImport = this.vuetifyImport()

      const Component = await ComponentImport
      const store = await StoreImport
      const vuetify = await VuetifyImport
      await import("lib/vue_extensions/store")

      const el = this.componentTarget
      const data = Object.assign({}, this.componentTarget.dataset)
      const props = JSON.parse(data.props || "{}")

      const app = new Vue({
        el,
        vuetify,
        store,
        render: h => h(Component, { props }),
        beforeMount() {
          this.$originalEl = this.$el.outerHTML;
        },
        destroyed() {
          this.$el.outerHTML = this.$originalEl;
        }
      })

      el.vueComponent = app
    } catch(err) {
      console.error(err)
    }
  }
}
