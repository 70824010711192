import {Controller} from "stimulus";
import KipuHighlighter from "lib/kipu-highlighter";

export default class extends Controller {
	static values = {resolveOnly: Boolean};

	connect() {
		window.highlighter = window.highlighter || new KipuHighlighter(window.rangy);
	}

	collapseComments(evt) {
		evt.currentTarget.hidden = true;

		if (!this.resolveOnlyValue) {
			window.highlighter.removeAllHighlights();
		}

		const displayCommentsLink = document.querySelector('a#display_comments_link');
		const commentsContainer = document.querySelector('div#comments_container');

		displayCommentsLink.hidden = false;
		commentsContainer.hidden = true;
	}

	displayComments(evt) {
		evt.currentTarget.hidden = true;

		if (!this.resolveOnlyValue) window.highlighter.restoreAndApplyAllHighlights();

		const collapseCommentsLink = document.querySelector('a#collapse_comments_link');
		const commentsContainer = document.querySelector('div#comments_container');

		collapseCommentsLink.hidden = false;
		commentsContainer.hidden = false;
	}
}
