import {Controller} from "stimulus";

const debounce = require("lodash/debounce");

export default class extends Controller {
	initialize() {
		this.#updateFormAction(this.element);
		this.updateNewCommentsFormButtons = debounce(this.updateNewCommentsFormButtons, 250, {leading: true}).bind(this);
		$('textarea#reviews_comment_content').css('height', '60px').autosize().focus();
	}

	cancelNewComment() {
		window.highlighter.removeLastHighlight();
	}


	submitNewComment(ev) {
		ev.currentTarget.form.classList.add('tw-invisible');
		document.getElementById('new_comment_spinner').classList.remove('tw-hidden');

		return true;
	}

	updateNewCommentsFormButtons() {
		const newCommentForm = document.querySelector('form#new_comment');

		if (!newCommentForm) return;

		const commentContentTextarea = document.querySelector('textarea#reviews_comment_content');
		const commentSubmitButton = document.querySelector('#comment_submit_button');

		commentSubmitButton.disabled = commentContentTextarea.value.length <= 3;
	}

	#updateFormAction(form) {
		const newCommentsLink = document.getElementById('new_comments_link');
		const formAction = form.getAttribute('action');
		const pathAndQueryStr = formAction.split('?');
		const params = new URLSearchParams(pathAndQueryStr[1]);
		params.set('commentable_type', newCommentsLink.dataset.commentableType);
		params.set('commentable_id', newCommentsLink.dataset.commentableId);
		params.set('metadata', newCommentsLink.dataset.metadata)
		const updatedFormAction = `${pathAndQueryStr[0]}?${params.toString()}`;
		form.setAttribute('action', updatedFormAction);
		newCommentsLink.remove();
	}
}
