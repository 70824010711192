import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['button', 'deletedInfo'];
  static classes = ['hidden'];

  connect () {
    this.class = this.hasHiddenClass ? this.hiddenClass : 'tw-hidden'
    this.isHidden = this.deletedInfoTargets[0]?.classList?.contains?.('tw-hidden') || !this.hasDeletedInfoTarget
    this.isHidden ? this.setDeletedHidden() : this.setDeletedVisible()
  };

  toggleVisibility(ev) {
    ev.stopPropagation()
    this.isHidden ? this.setDeletedVisible() : this.setDeletedHidden()
    this.dispatchModalRecordEvent()
  }

  setDeletedHidden() {
    this.isHidden = true
    for (const button of this.buttonTargets) {
      const container = button.querySelector('span') || button
      container.innerText = button.dataset.showText || 'Show Deleted'
    }
    for (const deletedInfo of this.deletedInfoTargets) {
      deletedInfo.classList.add('tw-hidden')
    }
  }

  setDeletedVisible() {
    this.isHidden = false
    for (const button of this.buttonTargets) {
      const container = button.querySelector('span') || button
      container.innerText = button.dataset.hideText || 'Hide Deleted'
    }
    for (const deletedInfo of this.deletedInfoTargets) {
      deletedInfo.classList.remove('tw-hidden')
    }
  }

  dispatchModalRecordEvent() {
    for (const tr of this.element.getElementsByTagName('tr')) {
      tr.dispatchEvent(new CustomEvent('hx-modal-toggle-deleted', { detail: { show: !this.isHidden } }))
    }
  }

  printModal() {
    document.getElementById('container').classList.add("print:tw-hidden");
    window.print();
    document.getElementById('container').classList.remove("print:tw-hidden");
  }
}
