import { Controller } from "stimulus"
import { fetchHeadless } from "@/lib/customFetch"
import { refreshCSRFTokens } from '@/lib/CSRFTokens'
import { htmlToElement, safeLoadHtml } from "@/lib/htmlToElement"
import { Deferred } from "lib/Deferred"

const camelize = s => s.replace(/-./g, x=>x[1].toUpperCase())

let dialogId = 0

const SquareBlock = `
<div
  class="tw-flex tw-flex-row tw-items-center tw-justify-center tw-h-full tw-w-full" role="image"
  aria-label="Initializing..."
>
  <div class="square-block tw-max-w-48">
    <div class="square-block--inner">
      <div class="square-block--content">
        <img src="/loader_wheels/wheel_w_message.svg?message=Initializing..." alt="Initializing" class="tw-w-full tw-h-full" />
      </div>
    </div>
  </div>
</div>
`

const DialogHTML = `
<div
  class="tw-h-screen tw-w-screen tw-fixed tw-top-0 tw-left-0 tw-overflow-none tw-z-50 tw-box-border-all"
  role="generic"
  data-[CONTROLLER_IDENTIFIER]-target="wrapper"
>
  <div class="tw-h-full tw-w-full tw-relative tw-p-4">
    <div
      class="tw-h-full tw-w-full tw-absolute tw-top-0 tw-left-0 tw-bg-k-gray-500 tw-opacity-50 tw-z-0"
      role="generic"
      data-action="click->[CONTROLLER_IDENTIFIER]#escapeDialog"
    ></div>
    <section
      class="tw-m-auto tw-rounded-lg tw-bg-white tw-shadow-md tw-container tw-z-1 tw-relative tw-flex tw-flex-col tw-flex-nowrap tw-overflow-hidden"
      role="dialog"
      aria-labelledby="[LABEL_ID]"
    >
      <header class="tw-flex-none tw-p-4 tw-border-none tw-grid tw-grid-flow-col tw-auto-cols-max tw-gap-2 tw-items-center tw-content-between tw-justify-between tw-bg-k-white tw-text-black">
        <h1>[TITLE]</h1>
        <button
          class="tw-rounded-full tw-bg-white tw-border-none tw-text-k-purple tw-h-8 tw-w-8 tw-p-0 focus:tw-border-true-blue focus:tw-border-solid focus:tw-border tw-cursor-pointer"
          aria-label="close"
          data-action="click->[CONTROLLER_IDENTIFIER]#escapeDialog"
        >
          <i class="mdi mdi-close tw-text-lg" role="generic"></i>
        </button>
      </header>
      <div
        class="tw-h-full tw-w-full tw-flex-1 tw-overflow-auto tw-p-4 tw-pt-0 tw-relative"
        data-dialog-target="document"
        role="document"
      >
        ${SquareBlock}
      </div>
    </section>
  </div>
</div>
`

const ConfirmClose = `
<p class="tw-text-bold tw-text-k-gray-900"><i><u>Closing may result in losing any unsaved changes</u></i></p>
<form action="#" method="GET" data-action="submit->dialog#submitConfirmation" class="tw-grid tw-grid-flow-col tw-auto-cols-max tw-gap-2 tw-content-between tw-justify-between">
  <button name="confirm" value="confirm" type="submit" class="tw-bg-k-red-600 tw-text-k-gray-200 tw-rounded tw-px-4 tw-py-2 tw-border-k-red-600 tw-border-solid tw-border hover:tw-bg-k-red-500 focus:tw-outline-none focus:tw-bg-k-red-500 focus:tw-border-k-purple-400 focus:tw-border-2">
    Yes
  </button>
  <button name="cancel" value="cancel" type="submit" class="tw-bg-k-true-blue-600 tw-text-k-gray-200 tw-rounded tw-px-4 tw-py-2 tw-border-k-true-blue-600 tw-border-solid tw-border hover:tw-bg-k-true-blue-500 focus:tw-outline-none focus:tw-bg-k-true-blue-500 focus:tw-border-k-purple-400 focus:tw-border-2">
    No
  </button>
</form>
`

const NoContent = `<p class="tw-text-center tw-text-xl">Nothing to Display</p>`

export default class DialogController extends Controller {
  static targets = [ "wrapper", "document" ]

  get identifierClass() {
    return this._identifierClass ||= camelize(this.identifier)
  }

  get dialogIsOpen() {
    return this._dialogIsOpen || false
  }

  set dialogIsOpen(value) {
    this._dialogIsOpen = !!value
    if(this.dialogIsOpen) document.body.classList.add("no-scroll")
    else document.body.classList.remove("no-scroll")
  }

  submitConfirmation(ev) {
    ev.preventDefault()
    this.returnValue = ev?.submitter?.value
    this.closeDialog()
  }

  connect() {
    this.activeElement = document.activeElement
    window.addEventListener("dialog-replace", this.replaceDialog)
    this.returnValue = null
    this.emitConnected()
  }

  disconnect() {
    window.removeEventListener("dialog-replace", this.replaceDialog)
    this.disableEventListeners()
    if(this.hasWrapperTarget) {
      this.closing = false
      this.confirmClose = false
      this.closeDialog()
    }
    this.returnValue = null
    this.emitDisconnected()
  }

  emitConnected() {
    const event = new CustomEvent("kipu:dialog:connected", {
      detail: {
        controller: this,
        element: this.element,
      }
    })
    document.dispatchEvent(event)
  }

  emitDisconnected() {
    const event = new CustomEvent("kipu:dialog:disconnected", {
      detail: {
        controller: this,
        element: this.element,
      }
    })
    document.dispatchEvent(event)
  }

  setValue(ev) {
    this.returnValue = ev?.detail?.value || ev?.currentTarget?.value
  }

  openDialog(ev) {
    if(this.dialogIsOpen) return false
    if(!this.hasWrapperTarget || !this.wrapperTarget.contains(document.activeElement)) this.activeElement = document.activeElement
    this.dialogIsOpen = true
    this.runDialogOpen(ev)
  }

  escapeDialog() {
    if(this.nested) this.returnValue = "cancel"
    this.closeDialog()
  }

  escapeDialogKeyboard = (evt) => {
    if(!this.dialogIsOpen || this.closing) return false
    if(evt.key === "Escape" && !evt.defaultPrevented && document.activeElement?.tagName?.toUpperCase?.() != "INPUT") {
      evt.preventDefault()
      this.escapeDialog()
    }
  }

  replaceDialog = (ev) => {
    if(this.nested) return false
    if(this.dialogIsOpen) {
      this.documentTarget.innerHTML = ev.detail.bodyContent
      refreshCSRFTokens()
      this.confirmClose = String(ev.detail.confirmClose).toLowerCase() === "true"
    } else {
      this.openDialog(ev)
    }
  }

  enableEventListeners = () => {
    if(!this.listening && this.dialogIsOpen && this.focusEv) {
      this.listening = true
      document.addEventListener("focusout", this.focusEv)
      document.addEventListener("focusin", this.focusEv)
      document.addEventListener("keyup", this.escapeDialogKeyboard)
    }
  }

  disableEventListeners = () => {
    if(this.listening) {
      this.listening = false
      document.removeEventListener("focusout", this.focusEv)
      document.removeEventListener("focusin", this.focusEv)
      document.removeEventListener("keyup", this.escapeDialogKeyboard)
    }
  }

  closeDialog() {
    this.executeClose()
  }

  shouldConfirm() {
    this.confirmClose = true
  }

  shouldNotConfirm() {
    this.confirmClose = false
  }

  runConfirmClose = () => {
    const confirming = new Deferred()

    try {
      this.disableEventListeners()

      const body = this.wrapperTarget.querySelector("[role=document]")
      const controllerWas = body.dataset.controller

      try {
        const onClose = ev => {
          if(ev?.detail?.element === this.element || ev?.detail?.element === body) {
            if(confirming?.controller?.hasWrapperTarget) body.removeChild(confirming.controller.wrapperTarget)
            body.dataset.controller = controllerWas
            document.removeEventListener("kipu:dialog:close", onClose)
            document.removeEventListener("kipu:dialog:connected", onConnect)
            if(!confirming.completed) {
              confirming.resolve(ev.detail.returnValue)
              this.enableEventListeners()
            }
          }
        }

        const onConnect = ev => {
          if(ev?.detail?.element === body) {
            if(confirming.completed) {
              onClose(ev)
            } else {
                confirming.controller = ev.detail.controller
                ev.detail.controller.openDialog({
                  detail: {
                    bodyContent: ConfirmClose,
                    title: "Are You Sure You Want To Close?",
                    nested: "true"
                  }
                })
            }
            document.removeEventListener("kipu:dialog:connected", onConnect)
          }
        }

        document.addEventListener("kipu:dialog:connected", onConnect)
        document.addEventListener("kipu:dialog:close", onClose)

        body.dataset.controller = `${controllerWas} dialog`
      } catch(err) {
        console.error(err)
        confirming.resolve()
        body.dataset.controller = controllerWas
      }
    } catch(err) {
      console.error(err)
      confirming?.resolve?.()
      this.enableEventListeners()
    }

    return confirming.promise.then(v => v === "cancel")
  }

  async executeClose() {
    if(this.closing) return false
    try {
      this.closing = true
      const beforeCloseListeners = []

      /* run teardown operations */
      const closing = new CustomEvent("kipu:dialog:closing", {
        cancelable: true,
        detail: {
          controller: this,
          returnValue: this.returnValue,
          element: this.element,
          beforeClose(cb) {
            beforeCloseListeners.push(cb)
          }
        }
      })

      let cancel = !document.dispatchEvent(closing),
          confirm = this.confirmClose

      const canceler = () => cancel = true,
            confirmer = () => confirm = true

      await Promise.allSettled(beforeCloseListeners.map(cb => cb(canceler, confirmer)))

      if(!cancel && confirm) cancel = await this.runConfirmClose()

      if(!cancel) {
        this.wrapperTarget.removeEventListener("focusout", this.focusEv)

        const closed = new CustomEvent("kipu:dialog:close", {
          cancelable: false,
          detail: {
            controller: this,
            element: this.element,
            returnValue: this.returnValue
          }
        })

        document.dispatchEvent(closed)

        this.confirmClose = this.dialogIsOpen = this.nested = false

        if(this.hasWrapperTarget) this.element.removeChild(this.wrapperTarget)

        this.disableEventListeners()

        this.activeElement.focus()
      }
    } catch(err) {
      console.error(err)
    } finally {
      this.closing = false
    }
  }

  wrapperTargetConnected(element) {
    const event = new CustomEvent("kipu:dialog:open", {
      detail: {
        controller: this,
        element,
      }
    })
    document.dispatchEvent(event)
  }

  wrapperTargetDisconnected(element) {
    /* run teardown operations */
    const event = new CustomEvent("kipu:dialog:disconnected", {
      cancelable: false,
      detail: {
        controller: this,
        returnValue: this.returnValue,
        element,
      }
    })

    document.dispatchEvent(event)
    this.confirmClose = false
    this.closeDialog()
  }

  async runDialogOpen(ev) {
    let wrapper, request
    try {
      const options = Object.assign(
        {},
        ev?.currentTarget?.dataset || {},
        { title: ev?.currentTarget?.dataset?.title || ev.currentTarget?.title },
        ev.detail || {},
      )
      let currentId = ++dialogId
      this.confirmClose = !!options.confirmClose

      wrapper = htmlToElement(
        DialogHTML
          .replace(/\[CONTROLLER_IDENTIFIER\]/g, this.identifier)
          .replace(/\[LABEL_ID\]/g, `kipu-dialog-label-${currentId}`)
          .replace(/\[TITLE\]/g, options.title || "")
      )

      this.nested = String(options.nested) === "true"

      const dialog = wrapper.querySelector("[role=dialog]")
      const body = dialog.querySelector("[role=document]")

      if(options.contentPath) {
        const url = new URL(options.contentPath, document.location)
        request = fetchHeadless(url, {html: true})
          .then(result => result.text())
          .then(content => {
            const loader = dialog.querySelector("[role=image]")
            body.prepend(...safeLoadHtml(content.trim() || NoContent))
            body.removeChild(loader)
          })
      } else if(options.bodyContent) {
        body.innerHTML = options.bodyContent.trim() || NoContent
      } else {
        body.innerHTML = NoContent
      }

      if(this.nested) {
        wrapper.querySelector("header").removeChild(wrapper.querySelector("header button"))
        wrapper.firstElementChild.classList.add("tw-flex", "tw-flex-col")
        dialog.classList.remove("tw-container")
        dialog.classList.add("tw-basis-1/2", "tw-grow-1", "tw-shrink-0")
     } else if(String(options.grow) === "true") {
        if(String(options.center) == "true") {
          dialog.classList.add(
            "tw-top-1/2",
            "tw-transform",
            "tw--translate-y-1/2"
          )
        }
        dialog.classList.add(
          "tw-max-h-full",
          "tw-min-h-screen-1/4",
        )
      } else if(String(options.halfScreen) == "true") {
        dialog.classList.add(
          "tw-w-1/2",
          "tw-min-h-3/4"
        )
      } else {
        dialog.classList.add(
          "tw-h-full"
        )
      }

      // allow for some customization, no impact on existing behavior/usage
      if(String(options.customClasses).length) {
        dialog.classList.add(...String(options.customClasses).split(' '));
      }

      this.element.appendChild(wrapper)
      this.focusEv = fev => {
        if(!wrapper.contains(document.activeElement)) {
          let target
          if(fev.type === "focusout" && wrapper.contains(fev.relatedTarget)) {
            target = fev.relatedTarget
          } else if(this.nested) {
            target = dialog.querySelector("button")
          } else {
            target = wrapper.querySelector("header button")
          }
          target = target || dialog
          target.focus()
        }
      }
      this.enableEventListeners()
      this.nested ? wrapper.querySelector("button[name=confirm]")?.focus?.() : dialog.focus()

      await request
    } catch(err) {
      console.error(err)
      this.dialogIsOpen = false
      try { this.element.removeChild(wrapper) } catch(_) {}
      request?.controller?.abort?.()
    }
    refreshCSRFTokens()
  }

  updateContentPath(ev) {
    if (ev.currentTarget.dataset.contentPath && ev.detail) {
      let u = ""
      u = new URL(ev.currentTarget.dataset.contentPath.split("?")[0], document.location)
      u.searchParams.append(Object.keys(ev.detail), Object.values(ev.detail));
      ev.currentTarget.dataset.contentPath = u.toString()
    }

  }
}
