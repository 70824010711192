import { Controller } from "stimulus";
import KipuHighlighter from "lib/kipu-highlighter";

export default class ReviewsCommentablesController extends Controller {
  static values = { resolveOnly: Boolean, userCanComment: Boolean }

  connect() {
    window.highlighter = window.highlighter || new KipuHighlighter(window.rangy);
    if (!this.resolveOnlyValue && this.userCanCommentValue) {
      this.#initTextSelection(this.resolveOnlyValue);
    }
  }

	initScrollingToComment(evt) {
		const commentableId = evt.currentTarget.id.split('_').pop();
		const targetElementSelector = `[data-reviews-comment-commentable-id-value="${commentableId}"]`;
		const targetElement = document.querySelector(targetElementSelector);
		if (!targetElement) return;

		this.#scrollTo(targetElement, 'div#comments_container');
	}

	#initTextSelection(resolveOnly) {
		const subNavContent = document.getElementById('sub_nav_content');

		['mouseup', 'keyup'].forEach((e) => {
			subNavContent.addEventListener(e, (evt) => {
				this.#generateNewCommentLink(resolveOnly, evt.clientX, evt.clientY);
			});
		});
	}

	#generateNewCommentLink(resolveOnly, pageX, pageY) {
		const existingNewCommentsLink = document.querySelector('button#new_comments_link');
		if (existingNewCommentsLink) {
			existingNewCommentsLink.remove();
			window.highlighter.removeLastHighlight();
		}

		const selection = window.highlighter.getSelection();
		const selectionText = selection.toString().trim();

		if (!document.querySelector('button#new_comments_link') && selectionText.length) {
			const evalItemNodeForFocusNode = this.#validateSelection(selection);

			if (evalItemNodeForFocusNode) {
				const evalItemId = evalItemNodeForFocusNode.id.split('_').pop();
				const containerElementId = `evaluation_item_${evalItemId}`;
				const serializedSelection = window.highlighter.serialize(evalItemId, containerElementId);

				const newCommentsLink = document.createElement('button');
				newCommentsLink.type = "submit";
				newCommentsLink.name = "new_commments_link";
				newCommentsLink.id = 'new_comments_link';
				newCommentsLink.className = 'tw-text-right bound tw-bg-white tw-rounded-lg tw-border-gray-300 tw-cursor-pointer';
				newCommentsLink.style = `z-index:999999; position:fixed; left:57%; top:${pageY - 10}px;`;
				newCommentsLink.href = '#/';
				newCommentsLink.dataset.grow = "true";
				newCommentsLink.dataset.center = "true";
				newCommentsLink.dataset.customClasses = "tw-max-w-sm";
				newCommentsLink.dataset.title = "Add Comment";
				newCommentsLink.dataset.commentableType = 'PatientEvaluationItem';
				newCommentsLink.dataset.commentableId = evalItemId;
				newCommentsLink.dataset.metadata = JSON.stringify({
					serializedSelection: serializedSelection,
					selectionText: selectionText
				})
				newCommentsLink.dataset.contentPath = "/reviews/comments/new_comment_panel";
				newCommentsLink.dataset.action = "click->dialog#openDialog";
				newCommentsLink.innerHTML = '<i id="new_comments_icon" class="mdi mdi-comment-text-outline mdi-36px mdi-dark tw-cursor-pointer"\n' +
					'icon="comment-text-outline"' +
					'size="36"/>';

				const faceSheetContainer = document.getElementById('facesheet-container');
				faceSheetContainer.append(newCommentsLink);

				newCommentsLink.addEventListener('click', this.#newCommentsLinkListener, { once: true })
			}
		}
	}

	#findClosestEvaluationItem(node) {
		while (node) {
			if (node.classList && node.classList.contains('evaluation_item') && node.classList.contains('show')) {
				return node;
			}
			node = node.parentNode;
		}
		return null;
	}

	#validateSelection(selection) {
		return this.#findClosestEvaluationItem(selection.focusNode) || this.#findClosestEvaluationItem(selection.anchorNode);
	}

	#scrollTo(targetElement, scrollableContainerSelector) {
		const scrollableContainer = document.querySelector(scrollableContainerSelector);
		const topPos = targetElement.offsetTop;
		scrollableContainer.scrollTop = topPos - 26;
	}

	#newCommentsLinkListener = () => {
		const displayCommentsLink = document.querySelector('a#display_comments_link');
		if (displayCommentsLink) {
			displayCommentsLink.hidden = true;
			const collapseCommentsLink = document.querySelector('a#collapse_comments_link');
			if (collapseCommentsLink) collapseCommentsLink.hidden = false;
			window.highlighter.restoreAndApplyAllHighlights();
			const commentsContainer = document.querySelector('div#comments_container');
			if (commentsContainer) commentsContainer.hidden = false;
		}
	}
}
