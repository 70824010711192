import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "labGuarantor", "labGuardian", "labGuardianAsterisk" ]

  connect(){
    this.setLabGuarantor()
    this.setLabGuardian()
    this.setSelectedLabGuarantorGuardian()
  }

  setLabGuarantor(){
    let labBillToElement = document.getElementById('patient_patient_lab_bill_to_attributes_lab_bill_to');
    // let labBillToText = labBillToElement && labBillToElement.options[labBillToElement.selectedIndex].text;
    let labGuarantorTypeElement = document.getElementById('patient_patient_lab_bill_to_attributes_lab_guarantor_type');
    let labGuarantorTypeText = labGuarantorTypeElement && labGuarantorTypeElement.options[labGuarantorTypeElement.selectedIndex].text;
    let options = this.labGuarantorTarget.options;
    let matchedOption;

    if (labGuarantorTypeText == 'Self') {
      // We pass null for the first argument in Array because we're not trying to call a method on a specific object, but rather a global constructor.
      matchedOption = Array.apply(null, options).filter(option => {
        return option.value.includes('Patient');
      })[0];

      if (matchedOption !== undefined) {
        this.labGuarantorTarget.value = matchedOption.value;
      }
    }
  }

  setLabGuardian() {
    let labGuardianElement = document.getElementById('patient_patient_lab_bill_to_attributes_lab_guardian');
    let age = parseInt(labGuardianElement.dataset.age);
    if (age >= 18) {
      return;
    }

    let labGuardianValue = labGuardianElement && labGuardianElement.options[labGuardianElement.selectedIndex].val;
    let options = this.labGuardianTarget.options;
    let matchedOptions;

    if (labGuardianValue === undefined || labGuardianValue === "") {
      matchedOptions = Array.apply(null, options).filter(option => {
        if (option.dataset.labBillToRelationship !== undefined) {
          return option.dataset.labBillToRelationship === 'Guardian';
        }
      });
    }

    // If only single Guardian relationship exists, then auto select that option
    // If more than one Guardian relationship exists, then let Operator deside the option
    if (matchedOptions.length === 1) {
      this.labGuardianTarget.value = matchedOptions[0].value
    } else if (matchedOptions.length > 1) {
      this.labGuardianTarget.value = ''
    }

    // Add asterisk to Lab Guardian field in case of Patient age is under 18
    this.labGuardianAsteriskTarget.textContent = ' ✱';
  }

  loadLabContacts(){
    let patientId = document.getElementById("patient_patient_lab_bill_to_attributes_patient_id").value;

    setImmediate(async () => {
      try {
        const url = new URL( `/patients/${patientId}/lab_bill_to_valid_patient_contacts.json`, window.location);
        const response = await fetch(url, { method: "GET" });
        const result = await response.json();

        this.setGuarantorDropDownMenu(result);
        this.setGuardianDropDownMenu(result);
      }
      catch(err) {
        console.error(">>>>> setImmediate error: ",err);
      }
    })
  }

  setGuarantorDropDownMenu(result){
    let labGuarantorElement = document.getElementById("patient_patient_lab_bill_to_attributes_lab_guarantor");

    let arr = Array.from(labGuarantorElement.options);
    let texts = arr.map(function(guarantor){ return guarantor.text });
    let _this = this;

    result.forEach( function(guarantor){
      var optionToBeAdded = document.createElement("option");
      optionToBeAdded.text = guarantor.text;
      optionToBeAdded.value = guarantor.value;

      if (optionToBeAdded.value === "undefined"){return}; // Skip if no selection in dropdown yet.

      if (!texts.includes(optionToBeAdded.text)){
        _this.labGuarantorTarget.appendChild(optionToBeAdded);  // add option to dropdown
      }
    }, _this);
  }
  setSelectedLabGuarantorGuardian(){
    const  labGuarantorSelected = document.getElementById("lab_guarantor_selected");
    const  labGuardianSelected = document.getElementById("lab_guardian_selected");

    this.labGuarantorTarget.value = labGuarantorSelected.value;
    this.labGuardianTarget.value = labGuardianSelected.value;
  }

  loadValidInsuranceSubscribers(){
    let patientId = document.getElementById("patient_patient_lab_bill_to_attributes_patient_id").value;

    setImmediate(async () => {
      try {
        const url = new URL( `/patients/${patientId}/lab_bill_to_valid_insurance_subscribers.json`, window.location);
        const response = await fetch(url, { method: "GET" });
        const result = await response.json();

        this.setGuarantorDropDownMenu(result);
      }
      catch(err) {
        console.log("setImmediate err: ",err);
      }
    })
  }

  setGuardianDropDownMenu(result){
    let labGuardianElement = document.getElementById("patient_patient_lab_bill_to_attributes_lab_guardian");

    let arr = Array.from(labGuardianElement.options);
    let texts = arr.map(function(guardian){ return guardian.text });
    let _this = this;

    result.forEach( function(guardian){
      var optionToBeAdded = document.createElement("option");
      optionToBeAdded.text = guardian.text;
      optionToBeAdded.value = guardian.value;

      if (optionToBeAdded.value === "undefined"){return}; // Skip if no selection in dropdown yet.

      if (!texts.includes(optionToBeAdded.text)){
        _this.labGuardianTarget.appendChild(optionToBeAdded);  // add option to dropdown
      }
    }, _this);
  }
}
