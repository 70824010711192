export class Deferred {
  constructor() {
    this.promise = new Promise((resolve, reject)=> {
      this.reject = (value) => { this.completed = true; return reject(value) }
      this.resolve = (value) => { this.completed = true; return resolve(value) }
    })
  }
}

export default Deferred
