import { getMetaContent, getMetaElement } from './MetaTags'

export { getMetaContent, getMetaElement }

// Up-to-date Cross-Site Request Forgery token
export function csrfToken() {
  return getMetaContent("csrf-token")
}

 // URL param that must contain the CSRF token
export function csrfParam() {
  return getMetaContent("csrf-param")
}

// Make sure that all forms have actual up-to-date tokens (cached forms contain old ones)
export function refreshCSRFTokens() {
  document.querySelectorAll(`form input[name="${csrfParam()}"]`).forEach((input) => {
    input.value = csrfToken();
  })
}
