import {Controller} from "stimulus";

export default class extends Controller {
	static values = {
		revisable: Boolean, resolveOnly: Boolean, serializedSelection: String, commentableId: Number,
		newComment: Boolean, commentId: Number, displayAction: Boolean
	}

	connect() {
		if (this.newComment) this.#closeNewCommentModal();
		this.clickListener = this.initScrollingToCommentable.bind(this);
		this.element.addEventListener('click', this.clickListener);

		if ((this.newComment || this.displayAction) && this.revisable && !this.resolveOnly) {
			if (this.newComment) {
				// appears that new comment form modal interferes with highlighting, unless performed as next event in queue
				setTimeout(() => { this.#applyHighlight(this.commentableId, this.serializedSelection); }, 0);
				document.getElementById('comments_container').scrollTo(0,0);
			} else {
				this.#applyHighlight(this.commentableId, this.serializedSelection);
			}
		}

		$('div#comments textarea.text_autosize').autosize();
	}

	disconnect() {
		this.element.removeEventListener('click', this.clickListener);
	}

	cancelRevision(evt) {
		const reviseCommentLink = document.querySelector(`#revise_comment_${this.commentId}_link`);
		const textareaForContent = document.querySelector(`textarea#comment_${this.commentId}_content`);
		const container = document.querySelector(`div#update_comment_${this.commentId}_container`);

		reviseCommentLink.classList.toggle('hidden');
		container.classList.toggle('hidden');
		textareaForContent.readOnly = true;

		// required for resize trigger to work
		setTimeout(() => { $(textareaForContent).trigger('autosize.resize'); }, 0);
	}

	revise(evt) {
		const reviseCommentLink = evt.currentTarget;
		const commentId = reviseCommentLink.dataset.commentId;
		const container = document.querySelector(`div#update_comment_${commentId}_container`);
		const textareaForContent = document.querySelector(`textarea#comment_${commentId}_content`);

		container.classList.toggle('hidden');
		textareaForContent.readOnly = false;
		reviseCommentLink.classList.toggle('hidden');
	}

	initScrollingToCommentable() {
		const targetElementSelector = `div#${(this.resolveOnly ? 'patient_evaluation_item_' : 'evaluation_item_')}${this.commentableId}`;
		const el = document.querySelector(targetElementSelector);
		const commentIcon = el.querySelector('i.mdi-comment-text-outline');
		if (commentIcon) {
			commentIcon.scrollIntoView({ behavior: 'smooth', block: 'center' });
		} else {
			el.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}

	get displayAction() {
		return this.displayActionValue;
	}

	get commentId() {
		return this.commentIdValue;
	}

	get newComment() {
		return this.newCommentValue;
	}

	get revisable() {
		return this.revisableValue;
	}

	get resolveOnly() {
		return this.resolveOnlyValue;
	}

	get commentableId() {
		return this.commentableIdValue;
	}

	get serializedSelection() {
		return this.serializedSelectionValue;
	}

	#applyHighlight() {
		const containerElementId = `evaluation_item_${this.commentableId}`;
		window.highlighter.applyHighlight(this.commentableId, this.serializedSelection, containerElementId);
	}

	#closeNewCommentModal() {
		const commentCancelButton = document.getElementById('comment_cancel_button');
		if (commentCancelButton) commentCancelButton.click();
	}
}
