import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["searchTerm", "linkTab", "logTab"];
    static values = {
        selectedTab: String
    };
    updateContentPath = new CustomEvent("updateContentPath", {
        detail: {
            ids: "",
        },
    });

    initialize() {
        this.selectedTabValue = "link";
    }

    connect() {
        this.instructionPanel();
    }

    searchChartlink(event) {
        if (this.selectedTabValue === "link") {
            this.searchLinkTable(event);
        } else if (this.selectedTabValue === "log") {
            this.filterLogTable(event);
        }
    }

    activateLinkButton() {
        if (document.querySelectorAll('input[name="chart_id[]"]:checked').length > 1) {
            document.getElementById("link_chart_btn").removeAttribute("disabled")
            this.updateContentPath.detail.ids = this.getSelectedChartIds();
            document.getElementById("link_chart_btn").dispatchEvent(this.updateContentPath);
        } else {
            document.getElementById("link_chart_btn").setAttribute("disabled", "true");
        }
    }

    recheckCharts() {
        fetch("/chart_link/validate_chart_link?" + new URLSearchParams({ids: this.getSelectedChartIds()}))
            .then(response => response.text())
            .then(html => {
                document.querySelectorAll('[role="document"]')[0].innerHTML = html;
            })
            .catch(function(error){
                console.log(error);
            });
    }

    linkCharts() {
        let selected_chart_id = document.querySelector('input[name="selected_chart"]:checked').value
        fetch("/chart_link/link_charts?" + new URLSearchParams({ids: this.getSelectedChartIds(), selected_chart_id: selected_chart_id}))
            .then(response => response.text())
            .then(html => {
                document.querySelectorAll('[role="document"]')[0].innerHTML = html;
            })
            .catch(function(error){
                console.log(error);
            });
    }

    updateDialogCloseAction() {
        document.body.querySelector('button[data-action="click->dialog#escapeDialog"]').setAttribute("data-action", "click->chartlink#closeChartLinkModal click->dialog#escapeDialog");
    }

    closeChartLinkModal() {
        this.link_table.search(this.searchTermTarget.value).draw();
        document.getElementById("link_chart_btn").setAttribute("disabled", "true");
    }

    getSelectedChartIds() {
        let selected_ids = [];
        document.querySelectorAll('input[name="chart_id[]"]:checked').forEach(selected_chart => selected_ids.push(selected_chart.value));
        return selected_ids.toString();
    }

    toggleTab(event) {
        const active = "tw-inline-flex tw-mx-2 tw-py-2 tw-px-3 tw-whitespace-nowrap tw-items-center tw-text-lg tw-border-solid tw-border-b-4 tw-border-k-purple tw-text-k-purple";
        const inactive = "tw-inline-flex tw-mx-2 tw-py-2 tw-px-3 tw-whitespace-nowrap tw-items-center tw-text-lg tw-border-transparent tw-text-black hover:tw-bg-k-tropical-blue-400";
        let chartLinkUrl = "/chart_link/chart_link_history";
        let urlParams = "";
        let that = this;
        if (event.target.innerHTML === "Link Charts") {
            this.linkTabTarget.classList = active;
            this.logTabTarget.classList = inactive;
            fetch("/chart_link/chart_link_tab?" + new URLSearchParams({ids: this.getSelectedChartIds()}))
                .then(response => response.text())
                .then(html => {
                    document.getElementById("chart_link_content").innerHTML = html;
                    that.instructionPanel();
                    that.selectedTabValue = "link";
                    document.getElementById("export_history_btns").classList.add("tw-hidden");
                    document.getElementById("chart_link_instruction").classList.remove("tw-hidden");
                })
                .catch(function(error){
                    console.log(error);
                });
        } else {
            this.linkTabTarget.classList = inactive;
            this.logTabTarget.classList = active;
            document.getElementById("info-block").style.display = 'none';
            this.logTable();
            this.selectedTabValue = "log";
            document.getElementById("export_history_btns").classList.remove("tw-hidden");
            document.getElementById("chart_link_instruction").classList.add("tw-hidden");
        }

    }

    instructionPanel() {
        document.querySelectorAll(".info-icon").forEach(e => e.addEventListener("mouseover", function(){
            e.querySelectorAll(".info-container")[0].style.display = 'block';
        }));
        document.querySelectorAll(".info-icon").forEach(e => e.addEventListener("mouseleave", function(){
            e.querySelectorAll(".info-container")[0].style.display = 'none';
        }));
    }

    linkTable() {
        if (!$.fn.DataTable.isDataTable("#chart_link_results_table")) {
            let last_4_header = document.getElementById("last_4_header").value
            this.link_table = $("#chart_link_results_table").DataTable({
                ajax: {
                    "url": "/chart_link/chart_link_search"
                },
                bAutoWidth: false,
                retrieve: true,
                serverSide: true,
                paging: false,
                searching: true,
                order: [[ 1, "asc" ]],
                columnDefs: [ {
                    orderable: false,
                    targets:   0,
                    "render": function (data, type, full, meta){
                        return '<input type="checkbox" name="chart_id[]" value="'
                            + data.id + '" data-action="change->chartlink#activateLinkButton">';
                    }} ],
                columns: [{
                    "data": null,
                    "defaultContent": ""},
                    {"data": "first_name", "title": "First Name", className: "dt-left", "width": "10%"},
                    {"data": "last_name", "title": "Last Name", className: "dt-left", "width": "10%"},
                    {"data": "date_of_birth", "title": "Date of Birth", "defaultContent": "", className: "dt-right", "width": "7%"},
                    {"data": "ssn_last_4", "title": last_4_header, "defaultContent": "", className: "dt-right", "width": "7%"},
                    {"data": "mr", "title": "MR Number", "defaultContent": "", className: "dt-right", "width": "8%"},
                    {"data": "number", "title": "Patient ID", "defaultContent": "", className: "dt-right", "width": "7%"},
                    {"data": "location", "title": "Location", "defaultContent": "", className: "dt-left", "width": "15%"},
                    {"data": "admission_date", "title": "Admission Date", "defaultContent": "", className: "dt-right", "width": "8%"},
                    {"data": "discharge_date", "title": "Discharge Date", "defaultContent": "", className: "dt-right", "width": "8%"},
                    {"data": "id", "title": "Chart ID", "defaultContent": "", className: "dt-right", "width": "6%"},
                    {"data": "linked_charts", "title": "Linked", "defaultContent": "", "orderable": false, className: "dt-right", "width": "7%"},
                    {"data": "patient_master_id", "title": "Master ID", "defaultContent": "", className: "dt-right", "width": "7%"}],
                select: true,
                dom: '<"clear"><"clear">rt<"clear">',
                "drawCallback": function( settings )  {
                    if ($("#chart_link_search").val().length > 2) {
                        $("#chart_link_results_table_wrapper").removeClass("tw-hidden");
                    }
                }
            })
            document.getElementById("chart_link_results_table_wrapper").classList.add("tw-hidden");
        }
    }

    logTable() {
        if (!$.fn.DataTable.isDataTable("#chart_link_log_table")) {
            $.fn.dataTable.ext.classes.sPageButton = "pagination-item tw-relative tw-justify-center tw-inline-flex tw-items-center tw-bg-white tw-text-sm tw-font-normal tw-text-gray-700 tw-text-med hover:tw-bg-k-true-blue-50 tw-leading-9 tw-h-8 tw-w-8 tw-group tw-rounded-full";
            $.fn.dataTable.ext.classes.sPageButtonActive = "pagination-item tw-bg-white md:tw-inline-flex tw-relative tw-items-center tw-text-sm tw-font-normal tw-justify-center tw-h-8 tw-w-8 tw-text-k-purple-600";
            $.fn.dataTable.ext.classes.sPageButtonDisabled = "pagination-item tw-relative tw-justify-center tw-inline-flex tw-items-center tw-bg-white tw-text-sm tw-font-normal tw-text-gray-700 tw-text-light tw-leading-9 tw-h-8 tw-w-8 tw-group tw-rounded-full";
            let last_4_header = document.getElementById("last_4_header").value
            this.log_table = $("#chart_link_log_table").DataTable({
                ajax: {
                    "url": "/chart_link/chart_link_history"
                },
                bAutoWidth: false,
                retrieve: true,
                serverSide: true,
                pageLength: 25,
                paging: true,
                pagingType: "full_numbers",
                language: {
                    "info": "_START_ - _END_ of _TOTAL_",
                    infoEmpty: "0 - 0 of 0",
                    paginate: {
                        sFirst: '<i class="mdi mdi-page-first" ></i>',
                        sNext: '<i class="mdi mdi-chevron-right" ></i>',
                        sPrevious: '<i class="mdi mdi-chevron-left" ></i>',
                        sLast: '<i class="mdi mdi-page-last" ></i>'
                    }
                },
                searching: true,
                order: [[ 0, "desc" ]],
                columns: [
                    {"data": "updated_at", "name": "updated_at", "title": "Date", "defaultContent": "", className: "dt-left", "width": "10%"},
                    {"data": "updated_by", "title": "User", "defaultContent": "", className: "dt-left", "width": "15%"},
                    {"data": "patient_first_name", "title": "First Name", className: "dt-left", "width": "10%"},
                    {"data": "patient_last_name", "title": "Last Name", className: "dt-left", "width": "10%"},
                    {"data": "patient_date_of_birth", "title": "Date of Birth", "defaultContent": "", className: "dt-left", "width": "7%"},
                    {"data": "patient_ssn_last_4", "title": last_4_header, "defaultContent": "", className: "dt-center", "width": "5%"},
                    {"data": "target_chart_id", "title": "Target", "defaultContent": "", className: "dt-center", "width": "4%"},
                    {"data": "linked_chart_ids", "title": "Linked Chart IDs", "defaultContent": "", "orderable": false, className: "dt-left", "width": "8%"}],
                dom: '<"clear">rt<"right" ip> <"clear">'
            });
            if (document.getElementById("chart_link_results_table_wrapper") !== null) {
                document.getElementById("chart_link_results_table_wrapper").classList.add("tw-hidden");
            }
            document.getElementById("link_chart_btn").classList.add("tw-hidden");
            document.getElementById("chart_link_log_table_info").classList.add("tw-font-sm")
            document.getElementById("chart_link_log_table_info").classList.add("tw-pr-5")
            this.searchTermTarget.value = "";
            this.searchTermTarget.placeholder = "Filter";
        }
    }

    searchLinkTable(event) {
        this.linkTable();
        document.getElementById("input_warning").innerHTML = "";
        if (event.key === "Enter" || event.type === "click"){
            let searchTerm = this.searchTermTarget.value;
            if (searchTerm.length > 2){
                document.getElementById("ajax_working").style.display = "block";
                document.getElementById("link_chart_btn").classList.remove("tw-hidden");
                this.link_table.search(searchTerm).draw();
                document.getElementById("link_chart_btn").setAttribute("disabled", "true");
                document.getElementById("ajax_working").style.display = "none";
            } else {
                document.getElementById("input_warning").innerHTML = "A minimum of 3 characters are required to run a search.";
            }
        }
    }

    filterLogTable(event) {
        this.logTable();
        document.getElementById("input_warning").innerHTML = "";
        document.getElementById("ajax_working").style.display = "block";
        this.log_table.search(this.searchTermTarget.value).draw();
        document.getElementById("ajax_working").style.display = "none";

    }

    exportLog(event) {
        document.getElementById("ajax_working").style.display = "block";
        const form = event.currentTarget.parentElement;
        form.querySelector("[name='search']").value = this.searchTermTarget.value;
        form.querySelector("[name='sort_column']").value = this.log_table.settings().init().columns[this.log_table.order()[0][0]]["data"];
        form.querySelector("[name='sort_order']").value = this.log_table.order()[0][1];
        document.getElementById("ajax_working").style.display = "none";
    }
}