import { Controller } from "stimulus"

export default class extends Controller {
  clearPatientFilters(){
    $('.form-group').find('.form-control').val('').change();
    let minDateFilter = ''
    let maxDateFilter = ''
    $.fn.dataTable.tables({api: true}).search('').columns().search('').draw();
  }
  
  clearFilter(){
    $('.form-group').find('.form-control').val('').change();
    $('.dropdown-menu li').removeClass("active")
    $('.filter[data-attendances-filter-loc-name="all"]').addClass('active')
    $('.filter[data-attendances-filter-program-id="0"]').addClass('active')
    $.fn.dataTable.tables({api: true}).search('').columns().search('').draw();
    Array.from(document.getElementsByClassName('watch-attendance-table')).forEach((element) => {
      element.dispatchEvent(new Event('attendanceTableUpdated'))
    })
  }
}