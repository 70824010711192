import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "default", "body" ]
  static values = { options: Array }

  initialize() {
    this.elementNumber = 0
  }

  setDefault(event) {
    if(event.target.checked) {
      this.defaultTargets.forEach((element, index) => {
        element.checked = false
      })
      event.target.checked = true
    }
  }

  addRow() {
    const elementNumber = this.nextElementNumber
    const row = this.newElement("tr")

    row.appendChild(this.createTextInput("account_number", elementNumber))
    row.appendChild(this.createTextInput("description", elementNumber))
    row.appendChild(this.createMultiSelect(elementNumber))
    row.appendChild(this.createCheckBox("is_enabled", elementNumber))
    row.appendChild(this.createCheckBox("is_default", elementNumber))

    this.bodyTarget.appendChild(row)

    $(".providers_select").select2()
  }

  createTextInput(name, number) {
    const td = this.newElement("td")
    const input = this.newElement("input")

    input.setAttribute("name", `provider_accounts[new_${number}][${name}]`)
    input.setAttribute("type", "text")

    td.appendChild(input)

    return td
  }

  createCheckBox(name, number) {
    const td = this.newElement("td")
    const input = this.newElement("input")

    input.setAttribute("name", `provider_accounts[new_${number}][${name}]`)
    input.setAttribute("type", "checkbox")

    td.appendChild(input)

    return td
  }

  createMultiSelect(number) {
    const td = this.newElement("td")
    const field = this.newElement("select")

    field.setAttribute("name", `provider_accounts[new_${number}][providers][]`)
    field.setAttribute("class", "providers_select")
    field.setAttribute("data-plugin", "select2")
    field.setAttribute("data-theme", "kipu")

    field.appendChild(this.newElement("option"))
    this.optionsValue.forEach((option) => {
      const tag = this.newElement("option")
      tag.setAttribute("value", option[1])
      tag.innerHTML = option[0]
      field.appendChild(tag)
    })

    td.appendChild(field)

    return td
  }

  newElement(tag, ...classes) {
    const el = document.createElement(tag)
    if(classes.length) el.classList.add(...classes)
    return el
  }

  get nextElementNumber() {
    ++this.elementNumber
    return this.elementNumber
  }
}
