import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "primaryInsurance",
    "primaryInsuranceAsterisk",
    "secondaryInsurance",
    "patientId"
  ]

  initialize(){
    sessionStorage.removeItem('patientLabBillToLabPrimaryInsurance');
  }

  connect(){
    this.setRequiredLabPrimaryInsurance()
    this.changeLabSecondaryInsurance()
  }

  setRequiredLabPrimaryInsurance() {
    let labBillToElement = document.getElementById("patient_patient_lab_bill_to_attributes_lab_bill_to");
    let labBillToText = labBillToElement && labBillToElement.options[labBillToElement.selectedIndex].text;

    if (labBillToText === "Insurance") {
      this.primaryInsuranceAsteriskTarget.textContent = ' ✱';
      // TODO: Need to make sure how it would work, un-comment accordingly in else part as well
      // this.primaryInsuranceTarget.required = true;
    } else {
      this.primaryInsuranceAsteriskTarget.textContent = '';
      // this.primaryInsuranceTarget.required = false;
    }
  }

  changeLabSecondaryInsurance() {
    let labPrimaryInsuranceElement = document.getElementById("patient_patient_lab_bill_to_attributes_lab_primary_insurance");
    let labPrimaryInsuranceValue = labPrimaryInsuranceElement && labPrimaryInsuranceElement.options[labPrimaryInsuranceElement.selectedIndex].value;

    // Disable lab_secondary_insurance field if lab_primary_insurance is not selected yet
    if (labPrimaryInsuranceValue === "") {
      this.secondaryInsuranceTarget.disabled = true;
    } else {
      this.secondaryInsuranceTarget.disabled = false;
      let labSecondaryInsuranceElement = document.getElementById('patient_patient_lab_bill_to_attributes_lab_secondary_insurance');
      let parsedStr = JSON.parse(sessionStorage.getItem('patientLabBillToLabPrimaryInsurance'));

      // Restoring option in lab_secondary_insurance, which recently is selected in lab_primary_insurance
      if (![null, {}].includes(parsedStr)) {
        var optionExists = false;
        Array.from(labSecondaryInsuranceElement.options).forEach(function (optionElement) {
          if (optionElement.value !== '' && optionElement.value === labPrimaryInsuranceValue) {
            optionExists = true;
          }
        });

        // Add new option only if labPrimaryInsuranceValue is 'Unassigned' or option not exists already in secondaryInsuranceTarget
        if (labPrimaryInsuranceValue !== '' || !optionExists) {
          var optionToBeAdded = document.createElement("option");
          optionToBeAdded.text = parsedStr.text;
          optionToBeAdded.value = parsedStr.value;
          this.secondaryInsuranceTarget.appendChild(optionToBeAdded);
        }
      }

      // Find the Option to be removed
      let optionToBeRemoved = Array.apply(null, labSecondaryInsuranceElement.options).filter(option => {
        if (option.value !== '') {
          return option.value === labPrimaryInsuranceValue;
        }
      })[0];

      // Keeping option in local storage, so that we can restore it later if User changes Primary Drop-down again
      if (optionToBeRemoved.value !== undefined) {
        sessionStorage.setItem(
            'patientLabBillToLabPrimaryInsurance',
            JSON.stringify({
              text: optionToBeRemoved.text,
              value: optionToBeRemoved.value
            })
        );
      }

      // Remove an option from drop-down list for lab_secondary_insurance field, we are removing selected option of lab_primary_insurance from list of lab_secondary_insurance
      optionToBeRemoved.remove();
    }
  }

  populateDropDownMenu(event) {
    console.log("populateDropDownMenu");
    const element = event.target
    const arr = Array.from(element.options)
    const values = arr.map(insurance => insurance.value)

    if (element === this.secondaryInsuranceTarget) {
      this.primaryInsuranceTarget.selectedOptions.forEach( o => values.push(o.value) )
    }

    this.fetchActiveInsurances().
      then(insurances =>
        insurances.forEach(insurance => {
          const optionToBeAdded = document.createElement("option")
          optionToBeAdded.text = insurance.text
          optionToBeAdded.value = insurance.value

          if (optionToBeAdded.value === "undefined"){return}; // Skip if no selection in dropdown yet.

          if (!values.includes(optionToBeAdded.value)){
            element.appendChild(optionToBeAdded);  // add option to dropdown
          }
        })
      )
  }

  fetchActiveInsurances() {
    const url = new URL( `/patients/${this.patientId}/lab_bill_to_valid_insurances.json`, window.location)
    const result = fetch(url).
      then(response => response.json())

    return result
  }

  get patientId() {
    return this.patientIdTarget.value
  }
}
