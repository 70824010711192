import { VueLoaderController } from "lib/stimulus-templates/vue-loader-controller"

export default class extends VueLoaderController {
  componentImport() {
    return import("components/marketplace").then(module => module.default)
  }

  storeImport() {
    return import("store/marketplace").then(module => module.marketplaceStore)
  }
}
