import { fetchHeadless } from "lib/customFetch"
import { safeLoadHtml } from "lib/htmlToElement"
import { Controller } from 'stimulus'

/*

  <table id="articles-datatable" class="table"
    data-controller="datatables"
    data-datatables-config-value="<%= {
      debug: true,
      serverSide: true,
      ajax: datatable_articles_path,
      dom: 'lfriptrip',
      columns: [
        {title: 'Title', data: 'title', width: "30%" },
        {title: 'Text', data: 'text', },
      ],
    }.to_json %>"
  >
  </table>

*/

var dt_id = 0
let dataTableStylesLoaded = false
export default class DataTablesController extends Controller {
  static values = {
    config: String,
  }
  loadDatatableStyles = () => {
    if(!dataTableStylesLoaded) {
      dataTableStylesLoaded = fetchHeadless("/assignable_documents/supporting_sources", {html: true})
        .then(response => response.text())
        .then(html => safeLoadHtml(html, true))
        .then(nodes => nodes.forEach(node => document.head.appendChild(node)))
        .catch(err => {
          console.error("Could Not Load Datatables Assets", err)
        })
    }
    return dataTableStylesLoaded
  }
  isTable = () => this.element.nodeName === 'TABLE'

  isDataTable = () => this.element.className.includes('dataTable')

  isPreview = () =>
    document.documentElement.hasAttribute('data-turbolinks-preview')

  isLive = () => this.dataTable

  isBooting = () =>
    this.isTable() && !this.isDataTable() && !this.isPreview() && !this.isLive()

  debug = (msg, extra = '') => {
    if(!this.config || !this.config.debug) return
    this.log(msg, extra)
  }

  log = (msg, extra = '') => {
    const pad = msg.length < 10 ? 10 - msg.length : 0
    console.debug('[DataTable]', this.dt_id || 0, msg, ' '.repeat(pad), this.element, extra)
  }

  initialize() {
    if(!this.isBooting()) return false

    this.dt_id = ++dt_id
    this.element.controller = this

    // Setting scrollY fixes page reload bug in autoWidth.
    const pre_config = Object.assign({ scrollY: undefined }, this.config)
    const config = this.hasConfigValue ? JSON.parse(this.configValue) : {}
    this.config = Object.assign({}, pre_config, config)

    this.debug('initialized', { config: this.config })
    return this.config
  }

  connect() {
    if(!this.isBooting()) return false

    this.originalParent = this.element.parentElement

    // Register the teardown listener and start up DataTable.
    document.addEventListener('turbolinks:before-render', this._teardown)

    this.connectDataTable()

    this.debug('connected', { controller: this })
    return this.config
  }

  connectDataTable = async () => {
    await this.loadDatatableStyles()

    if(!window.jQuery.fn.DataTable) {
      console.log("waiting for DataTables to load")
      return await new Promise(r => setTimeout(r, 100)).then(this.connectDataTable)
    }
    this.dataTable = window.jQuery(this.element).DataTable(Object.assign({}, this.config))
  }

  link() {
    const wrapper = this.element.closest(".dataTables_wrapper")
    if(wrapper) {
      this.controller = wrapper.querySelector(".dataTables_scrollBody table").controller
    }
  }

  _teardown = () => this.teardown()

  teardown(event) {
    if(!this.isLive()) return false

    document.removeEventListener('turbolinks:before-render', this._teardown)
    this.dataTable.destroy()
    this.dataTable = undefined

    this.debug('teardown')
    return this.config
  }
}
