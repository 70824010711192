import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "programId", "locName" ]

  filter(e) {
    let attendance_datatable = $.fn.dataTable.tables({api: true})
    e.preventDefault();
    let programColumn = 8;
    let urColumn = 10;
    let clinicalColumn = 12;
    Array.from(document.getElementsByClassName('filter')).forEach(function(el){
      el.classList.remove('active')
    });
    this.element.classList.add('active');
    if ($("th:contains(Location)").length == 0) {
      --programColumn;
      --urColumn;
      --clinicalColumn;
    }
    if (this.data.get('programId') && this.data.get('programId') === "0") {
      attendance_datatable.columns(programColumn).search('').draw();
      this.dispatchE()
    } else if (this.data.get('programId')) {
      let programName = $.fn.dataTable.util.escapeRegex(this.data.get("programName")).trim();
      attendance_datatable.columns(programColumn).search(programName ? '^' + programName + '$' : '', true, false, true).draw();
      this.dispatchE()
    }
    if (this.data.get('locName') && this.data.get('locName') === "all") {
      attendance_datatable.columns(urColumn, clinicalColumn).search('').draw();
      this.dispatchE()
    } else if (this.data.get('locName')) {
      let careLevelName = $.fn.dataTable.util.escapeRegex(this.data.get("locName")).trim();
      attendance_datatable.columns(urColumn, clinicalColumn).search(careLevelName ? '^' + careLevelName + '$' : '', true, false, true).draw();
      this.dispatchE()
    }
  }

  dispatchE(){
    Array.from(document.getElementsByClassName('watch-attendance-table')).forEach((element) => {
      element.dispatchEvent(new Event('attendanceTableUpdated'))
    })
  }
}
