import { Controller } from "stimulus"

export default class CheckboxColumnController extends Controller {
  static targets = ["disabled", "enabled", "checkbox"]
  static values = {
    disableSelector: String,
    enableSelector: String,
    speed: Number,
    disableAnimations: Boolean
  }

  get disableSelector() { return this.disableSelectorValue || "[data-checkbox-disable]" }
  get enableSelector() { return this.enableSelectorValue || "[data-checkbox-enable]" }
  get speed() { return this.speedValue || 500 }
  get noAnimate() { return this._noAnimate || this.disableAnimationsValue }
  set noAnimate(value) { this._noAnimate = !!value }

  connect() {
    this.noAnimate = true
    this.firstSort(this.disabledTarget)
    this.firstSort(this.enabledTarget)
    this.noAnimate = false
  }

  sortElement(ev) {
    const el = ev.currentTarget || ev
    const label = document.querySelector(`[for=${el.id}]`) || el.closest("label")
    const disableButton = label.querySelector(this.disableSelector)
    const enableButton = label.querySelector(this.enableSelector)
    if(el.checked) {
      this.moveElement(label, this.enabledTarget)
      disableButton.classList.remove("tw-hidden")
      enableButton.classList.add("tw-hidden")
    } else {
      this.moveElement(label, this.disabledTarget)
      disableButton.classList.add("tw-hidden")
      enableButton.classList.remove("tw-hidden")
    }
  }

  firstSort(target) {
    const labels = this.sortLabels(target)
    if(labels.length) {
      for(const label of labels) target.removeChild(label)
      for(const label of labels) target.appendChild(label)
    }
  }

  sortLabels(target, child) {
    const labels = [...target.querySelectorAll("label"), child]
      .filter(label => {
        if(!label) return false
        const checkbox = label.htmlFor
          ? document.getElementById(label.htmlFor)
          : label.querySelector("input[data-checkbox-column-target=checkbox]")
        return this.checkboxTargets.includes(checkbox)
      })
      .sort((left, right) => {
        left = left.innerText
        right = right.innerText
        const compareLower = left.toLowerCase().localeCompare(right.toLowerCase())
        if(compareLower === 0) return left.localeCompare(right)
        return compareLower
      })
    return labels
  }

  moveElement(child, target) {
    if(this.noAnimate) {
      const labels = this.sortLabels(target, child)
      const idx = labels.indexOf(child)
      if(idx === labels.length - 1) target.appendChild(child)
      else labels[idx + 1].before(child)
      return true
    }
    target._animatingCheckboxes = target._animatingCheckboxes || 0
    target._animatingCheckboxes = target._animatingCheckboxes + 1
    child.currentAnimation?.finish?.()
    const animations = [ { element: child, boundary: child.getBoundingClientRect() } ]

    const targetScroll = target.scrollTop

    target.classList.add("tw-overflow-visible")
    target.classList.remove("tw-overflow-y-auto")

    if(!target.style.transform) target.style.transform = `translate3d(0, -${targetScroll}px, 0)`

    const labels = this.sortLabels(target, child)
    const idx = labels.indexOf(child)
    if(idx === labels.length - 1) target.appendChild(child)
    else {
      for(let i = labels.length - 1; i > idx ; i--) {
        const element = labels[i]
        const boundary = element.getBoundingClientRect()
        if(i === idx + 1) element.before(child)
        animations.push({ element, boundary })
      }
    }

    for(const job of animations) {
      const { element, boundary: { left: x0, top: y0 } } = job

      const { left: x1, top: y1 } = element.getBoundingClientRect()

      const dx = x0 - x1
      const dy = y0 - y1

      if (dx === 0 && dy === 0) {
        return
      }

      const transformFrom = `translate3d(${dx}px, ${dy}px, 0)`
      const transformTo = `translate3d(0, 0, 0)`

      const animation = element.animate([
        { transform: transformFrom },
        { transform: transformTo },
      ], {
        duration: this.speed,
        easing: "ease-in-out",
      })
      element.currentAnimation = animation
      animation.finished.then(() => {
        if(element.currentAnimation === animation) element.currentAnimation = null
        target._animatingCheckboxes = target._animatingCheckboxes - 1
        if(target._animatingCheckboxes < 1) {
          target._animatingCheckboxes = 0
          target.classList.remove("tw-overflow-visible")
          target.classList.add("tw-overflow-y-auto")
          target.style.transform = ''
        }
      })
    }
  }
}
