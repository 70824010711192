import { Controller } from "stimulus"
import { csrfToken } from '@/lib/CSRFTokens'

// <tr data-controller='hx-modal-record'
// data-hx-modal-record-hidden-class="hidden"
// data-hx-modal-record-deleted-class="strikeout"
// data-hx-modal-record-deleted-value=<%= record.deleted? %>
// data-hx-modal-record-id-value=<%= record.id %>
// data-hx-modal-record-target='item'
// data-hx-modal-record-can-delete-value=<%= user_can_delete %>
// data-hx-modal-record-source-value=<%= record.record_source %>
// data-hx-modal-record-element-id-value="#orthostatic-vital-signs-refresh"
// data-action="hx-modal-setDisplay-deleted->hx-modal-record#setDisplay"
// data-hx-modal-record-url-value=<%= Rails.application.routes.url_helpers.patient_orthostatic_vital_sign_path(id: record.id, patient_id: @patient.id) %>>

export default class extends Controller {
  static targets = ['item', 'deletelink', 'deletedAt', 'deletedBy','button'];
  static classes = ['hidden', 'deleted'];
  static values  = { deleted: Boolean,
                     id: Number,
                     source: String,
                     canDelete: String,
                     url: String,
                     elementId: String
                   };

  initialize() {
    this.setButtonVisibility();
    this.setDisplay()
  }

  setButtonVisibility() {
    if (this.deletedValue || this.canDeleteValue == 'false' || this.sourceValue == 'PatientObservation' || this.sourceValue == 'PatientEvaluationItem') {
      this.deletelinkTarget.classList.add('tw-hidden')
    } else {
      this.deletelinkTarget.classList.remove('tw-hidden')
    }
  }

  setDisplay(ev) {
    if(this.deletedValue) {
      this.itemTarget.classList.add('tw-line-through')
      ev?.detail?.show
        ? this.itemTarget.classList.remove('tw-hidden')
        : this.itemTarget.classList.add('tw-hidden')
    } else {
      this.itemTarget.classList.remove('tw-line-through')
      this.itemTarget.classList.remove('tw-hidden')
    }
  }

  async deleteRecord() {
    if (!confirm("Are you sure you want to delete?" )) return false;

    const working = document.getElementById('ajax_working')

    try {
      working.style.display = "block"
      const response = await fetch(this.urlValue, {
        method: 'delete',
        credentials: 'include',
        mode: 'same-origin',
        headers: {
          'X-CSRF-Token': csrfToken(),
          'Content-Type': 'application/json'
        }
      })

      const json = await response.json()

      if($(this.elementIdValue).length > 0) {
        $(this.elementIdValue).html(json['attachmentPartial']);
      }
      this.deletedValue = true;
      this.deletedByTarget.innerHTML = json['deleted_by'];
      this.deletedAtTarget.innerHTML = json['deleted_at'];

      const toggleButton = document.getElementById('show-deleted-ia-button')
      this.setButtonVisibility()
      this.setDisplay({ detail: { show: toggleButton?.innerText?.includes?.(toggleButton?.dataset?.hideText) && !/show/i.test(toggleButton.innerText) } })
    } catch (error) {
      console.error(error)
    } finally {
      working.style.display = "none"
    }
  }
}
