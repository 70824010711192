import { Controller } from "stimulus"

/*
  This controller will add the value of any text you add as 
  data-background-text-value="example text", to the background 
  to any element it's added too. The default value is work in progress
 */

export default class extends Controller {
  static targets = ["label"]
  static values = {
    text: String,
    color: String
  }

  initialize(){
    this.svgText = this.textValue == '' ? 'WORK IN PROGRESS' : this.textValue
  }

  connect() {
    this.element.style.backgroundImage = `url("${this.createSvg(this.svgText)}")`
    this.element.style.backgroundPosition = 'center'
    this.element.style.backgroundRepeat = 'repeat'
  }

  createSvg(text = 'Work in Progress', color = 'red'){
    let svg = `data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='15px' width='130px'><text x='10' y='10' fill='${color}' fill-opacity='.25' font-size='12'>${text}</text></svg>`
    return svg
  }
}
